import { bigram, trigram, nGram } from "n-gram";
import { getSlug } from "./slug.js";
import { isMixed } from "./gimmu.js";
//import capitalize from "capitalize-sentence";

var slugify = require("slugify");
var unprocessedStopwords = require("./stopwords.js");
//import {stopwords as unprocessedStopwords} from './stopwords.js'

//export function stopwords() {

//console.log("text stopwords", unprocessedStopwords);
const stopwordsArray = unprocessedStopwords.default.split("\n");

const stopwords = stopwordsArray.map((element) => {
  return element.toLowerCase();
});

//return stopwords;
//console.log("text stopwords", stopwords)

//return stopwords;
//}

export function stripStopwords(text) {
  if (!text) {
    return;
  }

  const tokens = text.split(" ");

  const strippedTokens = tokens.filter((token) => {
    if (stopwords.includes(token.toLowerCase())) {
      return false;
    }
    return true;
  });

  const strippedText = strippedTokens.join(" ");
  return strippedText;
}
/*
function nGramify(text, l) {

const tokens = text.split(' ');


return nGram(l)(tokens).map((i)=>{
arr[getSlug(i.join(' '))] = i.join(' ');
});

}
*/

export function textCapitalize(string, lexicon) {
  //return string;
  //return rulesCapitalize(string, lexicon);
  return smartCapitalize(string, lexicon);
  //return capitalize(string);
}

export function rulesCapitalize(string, lexicon = null) {
  const tokens = string.split(" ");
  const capitalizedTokens = tokens.map((token) => {
    if (isMixed(token)) {
      return token.toUpperCase();
    }

    return capitalizeFirstLetter(token);
  });

  return capitalizedTokens.join(" ");
}

function extractNgrams(tokens) {
  var arr = [];
  var nGramsArray = nGram(5)(tokens).map((j) => {
    return j.join(" ");
  });
  nGramsArray.map((ngram) => {
    arr.push(ngram);
  });

  nGramsArray = nGram(4)(tokens).map((j) => {
    return j.join(" ");
  });
  nGramsArray.map((ngram) => {
    arr.push(ngram);
  });

  nGramsArray = nGram(3)(tokens).map((j) => {
    return j.join(" ");
  });
  nGramsArray.map((ngram) => {
    arr.push(ngram);
  });

  nGramsArray = nGram(2)(tokens).map((j) => {
    return j.join(" ");
  });
  nGramsArray.map((ngram) => {
    arr.push(ngram);
  });

  nGramsArray = nGram(1)(tokens).map((j) => {
    return j.join(" ");
  });
  nGramsArray.map((ngram) => {
    arr.push(ngram);
  });

  return arr;
}

export function smartCapitalize(string, lexicon) {
  var arr = [];
  var i = null;
  //console.log("lexicon", lexicon);
  lexicon.map((token) => {
    const tokens = token.split(" ");
    arr.push(...extractNgrams(tokens));
  });

  /*
const newArr = [];
for (var i = 0, l = arr.length; i < l; i++) {
  var value = (arr[i]);
newArr[getSlug(value)] = value;
}
*/
  console.log("text array", arr);

  var slug = getSlug(string);
  const stringTokens = string.split(" ");

  const stringArr = extractNgrams(stringTokens);
  console.log("stringArr", stringArr);

  const stylingArray = stringArr.map((string) => {
    const t = arr.filter((i) => {
      if (getSlug(i) === getSlug(string)) {
        return true;
      }
      return false;
    });

    return { text: string, slug: getSlug(string), styled: t };
  });

  console.log("ttt x", stylingArray);

  //console.log("slug text", spacedSlug);

  var spacedSlug = slug.replace(/-/g, " ");
  var lastSlug = spacedSlug;
  stylingArray.forEach((style) => {
    if (style.styled === []) {
      return;
    }

    console.log(
      "slug text",
      "(",
      style.text,
      ">",
      style.styled[0],
      ")",
      spacedSlug
    );

    var styling = style.styled[0];

    if (spacedSlug.includes(styling)) {
      return;
    }

    if (styling === undefined) {
      styling = rulesCapitalize(style.text);
    }
    lastSlug = spacedSlug;
    spacedSlug = spacedSlug.replace(style.text + " ", styling + " ");

    if (lastSlug === spacedSlug) {
      spacedSlug = spacedSlug.replace(" " + style.text, " " + styling);
    }
  });

  // const s = slug;
  const s = spacedSlug;

  //console.log("lexicon arr.length", arr.length, newArr);

  //var s = string.toLowerCase();
  /*console.log("slug text", spacedSlug);
const stringTokens = s.split(' ');
const nGramsStringArray = nGram(1)(stringTokens).map((j)=>{return j.join(' ');})

var s = "";
nGramsStringArray.map((token)=>{
s = s + " " + arr[getSlug(token)];
});
*/
  //newArr.map((i) => {

  //console.log("lexicon i", i);

  //});

  return s;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeEachWord(string) {
  return string.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
    letter.toUpperCase()
  );
}
/*
export function getSlug(text) {
  if (text === undefined) {return ""};
  if (text === null) {return ""};
//  return text.toLowerCase().replace(/\s+/g,"-");
//  return slugify(text,"-").toLowerCase();
  return text
             .toLowerCase()
             .replace(/[^\w ]+/g, '')
             //change the below - to a + for Amazon. MT
             .replace(/ +/g, '-');
}
*/
/*
export function getPositiveSlug(text) {
  if (text === undefined) {return ""};
  if (text === null) {return ""};
//  return text.toLowerCase().replace(/\s+/g,"-");
//  return slugify(text,"-").toLowerCase();
  return text
             .toLowerCase()
             .replace(/[^\w ]+/g, '')
             //change the below - to a + for Amazon. MT
             .replace(/ +/g, '+');
}
*/

export function formatPrice(amount) {
  const fixedNumber = Number.parseFloat(amount).toFixed(2);
  return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
