import React from "react";
import HeroSection2 from "./../components/HeroSection2";

function PrivacyPage(props) {
  return (
    <>
      <HeroSection2 />
    </>
  );
}

export default PrivacyPage;
