import { Box, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const ListSkeleton = ({ listsToRender }) => {
    return (
      <>
       <Grid container={true} spacing={0}>
        {Array(listsToRender)
          .fill(1)
          .map((card, index) => (
          
 <Grid

 xl={2}
 lg={3}
 md={4}
 sm={6}
 xs={12}
 //key={index}
>
<Box p={1} style={{width:'100%'}}>
              <Skeleton variant="rect" height={200} />
            </Box>
    </Grid>



          ))}
          </Grid>
      </>
    );
  };

  export default ListSkeleton;