import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { useWatch } from "react-hook-form";
import { formatPrice } from "./../util/text.js";
import { Button, Typography, Grid, Fade } from "@material-ui/core";
import ImageCarousel from "./../components/ImageCarousel";
import Skeleton from "@material-ui/lab/Skeleton";
import EbayItemButton from "./../components/EbayItemButton";
import AmazonItemButton from "./../components/AmazonItemButton";
import { useItemByItemId } from "./../util/ebay";
//import amazonbuy from "./../images/amazonbuy.png";
import LinearProgress from "@material-ui/core/LinearProgress";
import { humanMonthYearTime } from "./../util/time";
import { capitalizeEachWord } from "./../util/text";

const { REACT_APP_SITE_TITLE, REACT_APP_KEYWORD } = process.env;

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },

  name: {
    overflow: "hidden",
    display: "-webkit-box",
    // minHeight: "50px",
    WebkitLineClamp: 10,
    WebkitBoxOrient: "vertical",
  },
  colorPrimary: {
    backgroundColor: "#e91e1e",
    height: 20,
    borderRadius: 5,
  },
  barColorPrimary: {
    backgroundColor: "#4caf50",
    height: 20,
    borderRadius: 5,
  },
}));

function PriceMetrics(props) {
  const { filter, items, keywords } = props;
  var { flavour } = props;

  var { item } = props;

  const keywordsQuery =
    keywords === "" || keywords === undefined ? "something" : keywords;

  const countItems = items ? items.length : 0;
  const capitalizedWordsQuery = keywordsQuery.replace(
    /(^\w{1})|(\s+\w{1})/g,
    (letter) => letter.toUpperCase()
  );
  const classes = useStyles();
  const textDeals = countItems === 1 ? "Deal" : "Deals";

  const [status, setStatus] = useState('loading');

  const [price, setPrice] = useState();

  var isReady = countItems >= 1;

  // Do some maths. When the items change.
  useEffect(() => {
    var minimumPrice = false;
    var maximumPrice = false;

    items.map((item) => {
      // eBay pricing source
      const price = parseFloat(
        item.sellingStatus[0].convertedCurrentPrice[0].__value__
      );

      if (!minimumPrice) {
        minimumPrice = price;
      }
      if (!maximumPrice) {
        maximumPrice = price;
      }

      if (price > maximumPrice) {
        maximumPrice = price;
      }
      if (price < minimumPrice) {
        minimumPrice = price;
      }

      console.log("PriceMetrics price", price);
      console.log("PriceMetrics maximumPrice", maximumPrice);
      console.log("PriceMetrics minimumPrice", minimumPrice);
    });
setPrice({minimumPrice:minimumPrice, maximumPrice: maximumPrice});
setStatus('success');
  }, [items]);

  if (!isReady) {
    return (
      <Typography variant="h4">
        <Skeleton variant="rect" width="100%" />
      </Typography>
    );
  }
if (status !== 'success') {return null;}
  return (
    <Typography variant="h4">
      ${price.minimumPrice}{' to '}${price.maximumPrice}
    </Typography>
  );
}

export default PriceMetrics;
