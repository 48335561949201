import React, { useEffect, useState } from "react";
import { useRouter } from "./../util/router.js";
import GimmuItemList from "./../components/GimmuItemList";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Meta from "../components/Meta";
import Navbar from "./../components/Navbar";
import ReactGA from "react-ga4";

const { REACT_APP_DESCRIPTION } = process.env;

function CollectionPage(props) {
  // analytics.page();
 
  const [item, setItem] = useState([]);
  const router = useRouter();
  const id = router.query.eventid;
  const tid = router.query.tid;

  console.log("tid",tid)

  //const keywordTokens = extractAlphaTokens(keywords);
  const titleTokens = id.split('-');
  const processedTitle = titleTokens.slice(0, 9).join(" ");
  //const metaTitle = processedTitle.join(" ");

  // Capitalise first letter of each title meta
  const metaTitle = processedTitle.toLowerCase().replace(/^[\u00C0-\u1FFF\u2C00-\uD7FF\w]|\s[\u00C0-\u1FFF\u2C00-\uD7FF\w]/g, function(letter) {
    return letter.toUpperCase();
    });

  const eventStatus = "success";
  const _event = { from: "url", id: 1234, text: id };
  // ReactGA.send({ hitType: "pageview", page: "/{item}" });
  useEffect(() => {
    if (_event === null) {
      return <>NOTHING HERE</>;
    }
  }, [eventStatus, _event]);

  return (
    <>

<div style={{height:'100%'}}>
{id ? (
             <Meta title={metaTitle} description={REACT_APP_DESCRIPTION} /> 
             ) : null}

    
      {eventStatus === "loading" ? (
        <>
          <CircularProgress size={32} />
        </>
      ) : null}
      {eventStatus === "success" && _event !== null ? (
        <>
          <Box py={2}>
            <GimmuItemList event={_event} flavour={"direct-buy"} />
          </Box>
        </>
      ) : null}

      {eventStatus === "error" ? (
        <>No events retrieved. Please try to refresh your browser.</>
      ) : null}

      {eventStatus === "success" && _event === null ? (
        <>No event exists with that name.</>
      ) : null}
      </div>
    </>
  );
}

export default CollectionPage;
