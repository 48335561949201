import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRouter } from "./../util/router.js";
import AppBar from "@material-ui/core/AppBar";
import { getSlug } from "./../util/slug.js";
import useDarkMode from "use-dark-mode";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import { Hidden } from "@material-ui/core";
import { alpha, makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import { Link } from "./../util/router.js";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import Section from "./Section";
import Slide from "@material-ui/core/Slide";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { Alert } from "@material-ui/lab";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  // root: {
  //   flexGrow: 1,
  // },
  menuButton: {
    marginRight: theme.spacing(2),
  },

  icon: {
    // display: 'none',
    fontSize: "1.2em",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },

  toolbar: {
    borderBottom: "1px solid black",
  },

  alert: {
    // Let <Section> handle colors
    backgroundColor: "#ffffff",
    color: "#000",
    borderRadius: 0,
  },

  message: {
    width: "100%",
    textAlign: "center",
  },

  title: {
    flexGrow: 1,
    display: "flex",
    // fontSize:'1.4em',
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },

  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "transparent",
    border: "1px solid #000",
    "&:focus-within": {
      backgroundColor: "transparent",
      border: ".125rem solid #f44336",
    },
    marginLeft: 15,
    width: "auto",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "100%",
      display:'block',
    },
  },

  menu: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      display: 'none',
    },
  },

  searchMobile: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "transparent",
    border: "1px solid #000",
    "&:focus-within": {
      backgroundColor: "transparent",
      border: ".125rem solid #f44336",
    },
    // marginLeft: 15,
    width: "100%",
  },

  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    display: 'flex',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: '16px',
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    // width: "22ch",
    [theme.breakpoints.up("sm")]: {
      // width: "34ch",
      fontSize:'16px',
      "&:focus": {
        // width: "34ch",
      },
    },
  },
}));

export default function SearchAppBar(props) {
  const classes = useStyles();
  const router = useRouter();
  const {flavour} = props;

  console.log("router.path", router.location.pathname)
  //const id = router.query.itemid;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 70,
  });
  const {
    onClose,
    bgColor,
    textColor,
    text,
    linkPath,
    linkText,
    ...otherProps
  } = props;
  const path = router.pathname;
  const searchEnabled = false; // Algolia / Elastic search. Pay to play. Disable for launch.

  const darkMode = useDarkMode();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuState, setMenuState] = useState(null);

  const [keywords, setKeywords] = useState(null);

  const [query, setQuery] = useState();
  const [filteredQuery, setFilteredQuery] = useState();

  useEffect(() => {
    //console.log(path);

    const laststep = path.replace(/-/g, " ");
    var s = laststep.replace(/\//g, "");
    //s = null;
    setQuery(s);

    setFilteredQuery(stripIdFromTitle(s));
  }, [path]);

  // Use inverted logo if specified
  // and we are in dark mode
  const logo =
    props.logoInverted && darkMode.value ? props.logoInverted : props.logo;

  const handleOpenMenu = (event, id) => {
    // Store clicked element (to anchor the menu to)
    // and the menu id so we can tell which menu is open.
    setMenuState({ anchor: event.currentTarget, id });
  };

  let history = useHistory();

  const handleSubmit = (event) => {
    event.preventDefault();
    // change url

    const slug = getSlug(keywords);
    //window.location.href = "http://localhost:3000/" + slug;

    let path = slug;
    //    let history = useHistory();
    history.push(path);
  };

  function isNumeric(token) {
    if (typeof token != "string") return false; // we only process strings!
    return (
      !isNaN(token) && !isNaN(parseFloat(token)) // use type coercion to parse t>
    ); // ...and ensure strings of whitespace fail
  }

  function stripIdFromTitle(text) {
    var filteredPostTitle = text.toLowerCase();

    filteredPostTitle = filteredPostTitle.replace("httpsdev gimmu com", "");
    filteredPostTitle = filteredPostTitle.replace("for sale", "");
    filteredPostTitle = filteredPostTitle.replace("accessories", "");

    var tokens = filteredPostTitle.split(" ");
    var a = filteredPostTitle.split(" ");

    var lastToken = a[a.length - 1];

    if (isNumeric(lastToken)) {
      // 113992540786
      // 10000000000

      if (lastToken > 10000000000) {
        a.pop();
        var filteredPostTitle = a.join(" ");
      }
    }

    return filteredPostTitle;
  }

  // if (flavour === "searchscroll") return <SearchScroll />;
  if (router.location.pathname === "/") return null;
  return (
    // <Section bgColor={props.color} size="auto" >

<>




    
    <div className={classes.root}>


      
      <AppBar position="static" color="transparent" elevation={0} style={{position:'relative', zIndex:'99'}}>
        <Toolbar className={classes.toolbar}>
          {/* <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton> */}
          <Link to="/" style={{ textDecoration: "none" }}>

          <OfflineBoltIcon
            className={classes.icon}
            style={{
              fontSize: "3em",
              color: "#f44336",
              marginRight: ".1em",
            }}
          />
          </Link>
          <Typography
            variant="h4"
            className={classes.title}
            style={{
              color: "#000000",
              lineHeight: "1.1em",
              fontFamily: "lato",
              fontWeight: "900",
            }}
          >
            thedeals.<span style={{ fontWeight: "300" }}>today</span>
          </Typography>
          <IconButton
            // size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            className={classes.menu}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.search}>
            <form onSubmit={handleSubmit}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search 600,000+ Deals Today..."
                key={query}
                // defaultValue={
                //   filteredQuery == "notfound" ? null : filteredQuery
                // }
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => setKeywords(e.target.value)}
              />
            </form>
       
          </div>
        </Toolbar>
      </AppBar>
      <Hidden smUp>
        <Toolbar className={classes.toolbar}>
          {/* <div className={classes.search}> */}
          <div className={classes.searchMobile}>
            <form onSubmit={handleSubmit}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search 600,000+ Deals Today..."
                key={query}
                // defaultValue={
                //   filteredQuery == "notfound" ? null : filteredQuery
                // }
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => setKeywords(e.target.value)}
              />
            </form>
          </div>



          {/* </div> */}
        </Toolbar>
      </Hidden>

  <div style={{position:'fixed', top:0, zIndex:9, width:'100%'}}>
   


      <Slide appear={false} direction="down" in={trigger} style={{borderBottom:'3px solid #f2453d'}}>



<Alert
  variant="filled"
  icon={false}
  onClose={onClose}
  classes={{
    root: classes.alert,
    message: classes.message,
  }}
>

<>

<div style={{width:'100%'}}>
<Box style={{minHeight:40, display:'flex'}} display='flex'  alignItems='center'>

<OfflineBoltIcon
            className={classes.icon}
            style={{
              fontSize: "3em",
              color: "#f44336",
              marginRight: ".1em",
            }}
          />
  <div style={{flexGrow:'1'}} >
          {/* <div className={classes.search}> */}
          <div className={classes.searchMobile} >
            <form onSubmit={handleSubmit} >
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search 600,000+ Deals Today..."
                key={query}
                // defaultValue={
                //   filteredQuery == "notfound" ? null : filteredQuery
                // }
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => setKeywords(e.target.value)}
              />
            </form>
          </div>



          {/* </div> */}
        </div>
    
          </Box>
          </div> 
   
</>


</Alert>

</Slide>
</div>


      
    </div>
    </>
    // </Section>
    
  );
}
