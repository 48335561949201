import { useState, useEffect } from "react";
import Gimmu, { scoreItem, keywordsByKeyword } from "./../util/gimmu";

const gimmuServerUrl = process.env.REACT_APP_SERVER;

const axios = require("axios");

const cache = {};


export function useItemsByKeywords(inputText) {
  const text = inputText.trim();
  const [error, setError] = useState(null);
  const [status, setStatus] = useState("loading");
  const [data, setData] = useState([]);

  //const query = string;
  //const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!text) {
      console.log("ebay useItemsByKeywords no search text provided.");

      setData(null);
      //setError("no search text provided");
      //setStatus("error");
      setError(null);
      setStatus("success");
      return;
    }
    setStatus("loading");
    //console.log("ebay query", text);

    const fetchData = async () => {
      const startTime = new Date();
//      const siteUrl = gimmuServerUrl + "v1/findItemsAdvanced/?keywords=" +
//      text;

      const siteUrl = "https://gimmu.com/webhook_772f7e04/" + "v1/findItemsAdvanced/?keywords=" +
      text;

//console.log("ebay query xxx", text)
      // console.log("siteUrl",siteUrl)
      const options = {
        url: siteUrl,
        method: "GET",
        responseType: "json",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      axios(options)
        .then((response) => {

          if (response.data === "") {
            setData([]);
            setError(null);
            setStatus("success");

            console.log("ebay query no response", text)

            return { data: null, error: "Empty response.", status: "error" };
          }

          let conditionedData = response.data.items.map((element) => {
            return element;
          });
          console.log(
            "ebay useItemsByKeywords condition data",
            conditionedData.images
          );

          const runTime = new Date() - startTime;

          console.log(
            "ebay query", text, 
            conditionedData.length, runTime, "ms" 
          );


          setData(conditionedData);
          setError(null);
          setStatus("success");
        })

        .catch((error) => {
          // console.log(error);

          const runTime = new Date() - startTime;
          console.log("ebay query", text, error, runTime, "ms");

          setData(null);
          setError(error);
          setStatus("error");
          return { data: null, error: error, status: "error" };
        });
    };
    fetchData();
  }, [text]);

  return { data: data, error: error, status: status };
}


export function useItemByItemId(itemId) {
  //  if (itemId === undefined) {
  //    return "";
  //  }

  const [error, setError] = useState(null);
  const [status, setStatus] = useState("loading");
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!itemId) return;

      const siteUrl = "https://gimmu.com/webhook_772f7e04/" + "getSingleItem/?id=" + itemId;


    const fetchData = async () => {
      const options = {
  //      url: gimmuServerUrl + "getSingleItem/?id=" + itemId,
        url: siteUrl,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      };

      axios(options)
        .then((response) => {
          //console.log(response);
          const data = response.data;

          setData(data);
          setError(null);
          setStatus("success");

          //   return { data: data, error: null, status: "success" };
        })
        .catch((error) => {
          //console.log(error);
          setData(null);
          setError(error);
          setStatus("error");
        });
    };
    fetchData();
  }, [itemId]);
  return { data: data, error: error, status: status };
}

/**** HELPERS ****/

// Reducer for useQuery hook state and actions
export const reducer = (state, action) => {
  switch (action.type) {
    case "idle":
      return { status: "idle", data: undefined, error: undefined };
    case "loading":
      return { status: "loading", data: undefined, error: undefined };
    case "success":
      return { status: "success", data: action.payload, error: undefined };
    case "error":
      return { status: "error", data: undefined, error: action.payload };
    default:
      throw new Error("invalid action");
  }
};


function ebayFormatItems(items) {
  if (items === undefined) {
    return [];
  }

  return items.map(item => {
    item = {ebay:item}
    //item = ebayFormat(item)
    var variants = true;
    if (item.ebay && item.ebay.isMultiVariationListing) {
      if (item.ebay.isMultiVariationListing[0] === "false") {
        variants = false;

        //console.log("item",item.isMultiVariationListing[0]);
        //if ( item.isMultiVariationListing[0] == "true" ) {
        //            variants = false;
      }
    }
    item.variants = variants;

    // Not there yet here.


  //item.id = null;
  if (item.ebay && item.ebay.itemId) {
    item.id = item.ebay.itemId[0];
  }

  if (item.ebay && item.ebay.title) {
    item.title = item.ebay.title[0];
  }

  item.images = [];

  if (item.ebay && item.ebay.galleryURL) {
    item.images = [item.ebay.galleryURL[0]];
  }

  //console.log("ebayFormat item",item);
  //var k = item;
  
  if (item && item.images) {
    item.images = item.images.map((imageUrl, index) => {
      //return "test";
      return Gimmu.ebayGimmuUrl(imageUrl, item, index);
      //return gimmu.imageUrlEbayGimmu(imageUrl); // Ebay url to Gimmu url.
    });
  }







/*
    if (item && item.images) {
      console.log("prompt to rewrite image url");
      item.images = item.images.map(function(imageUrl, index) {
        return original(imageUrl, item, index);
      });

      //  item.images = item.images.map(item => (imageUrl, index) => {
      //  return gimmu.ebayGimmuUrl(imageUrl, item, index);
      //  });
    }
*/

//    item.id = item.itemId[0];

    //item.merp = "foobar";

    //}
    return item;
    //}
    //return ({...item, variants: variants});
  });

  //  items = {...i };

  //  return items;
}



function original(imageUrl, item, index) {
  return Gimmu.ebayGimmuUrl(imageUrl, item, index);

  //return
}


export function ebayURL(item) {



  // https://stackoverflow.com/questions/46858840/unexpected-string-concatenation

  if (item.ViewItemURLforNaturalSearch !== undefined) {
    return (
      item.ViewItemURLForNaturalSearch +
        "?var=&mkcid=1&mkrid=711-53200-19255-0&siteid=0&campid=5338622339&customid=gimmuV2&toolid=10001&mkevt=1"
    );
}

if (item.itemWebUrl !== undefined) {
    return(
      item.itemWebUrl +
        "?var=&mkcid=1&mkrid=711-53200-19255-0&siteid=0&campid=5338622339&customid=gimmuV2&toolid=10001&mkevt=1"
    );

}

if (item.viewItemURL !== undefined) {
  return(
    item.viewItemURL +
      "?var=&mkcid=1&mkrid=711-53200-19255-0&siteid=0&campid=5338622339&customid=gimmuV2&toolid=10001&mkevt=1"
  );

}




}