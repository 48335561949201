import { useState, useEffect } from "react";

// const var_dump = require("var_dump");

//import GimmuItemList from "../components/GimmuItemList";
import { getSlug } from "./slug.js";
//import { response } from "express";
import axios from "axios";

const matchScoreMin = 0;
const matchScoreMax = 99;

const minScore = 0;
const maxScore = 99;

const defaultKeywords = process.env.REACT_APP_KEYWORDS
  ? process.env.REACT_APP_KEYWORDS
  : "";

const siteSlug = process.env.REACT_APP_SITE_SLUG
  ? process.env.REACT_APP_SITE_SLUG
  : "treasure";


const defaultKeyword = process.env.REACT_APP_KEYWORD
  ? process.env.REACT_APP_KEYWORD
  : "";
//ocnst defaultKeywords = process.env.REACT_APP_KEYWORDS ? process.env.REACT_APP_KEYWORDS : "treasure";
const defaultNotKeywords = process.env.REACT_APP_NOTKEYWORDS.split(", ");

const gimmuServerUrl = process.env.REACT_APP_SERVER;
const gimmuImageServerUrl = process.env.REACT_APP_IMAGESERVER

//const { REACT_APP_SPREADSHEET } = process.env.REACT_APP_KEYWORD;
const defaultSiteWords = process.env.REACT_APP_SITEWORDS;
const minimumSiteScore = 1;

// function phpWorker($datagram) {

export function isAlphaNumeric(str) {
  var code, i, len;

  for (i = 0, len = str.length; i < len; i++) {
    code = str.charCodeAt(i);
    if (
      !(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123)
    ) {
      // lower alpha (a-z)
      return false;
    }
  }
  return true;
}

export function useQueryByKeywords(keywords, type) {

//console.log("keywords", keywords);

  const keywordTokens = extractAlphaTokens(keywords);
  const mixedTokens = extractMixedTokens(keywords);

//console.log("keyword Tokens", keywordTokens);
//console.log("mixedTokens", mixedTokens);

  var processedMixedTokens = [];
  if (mixedTokens !== null && mixedTokens.length > 0) {
    processedMixedTokens = mixedTokens.slice(0,4);
  }

  var processedKeywordTokens = keywordTokens.slice(0,4 - processedMixedTokens.length + 1);

  var processedTokens = [...processedMixedTokens, ...processedKeywordTokens];

//console.log("processedTokens", processedTokens);

  var initialQuery = defaultKeyword + " " + processedTokens.join(" ");
  if (defaultKeyword == "") {
    var initialQuery = processedTokens.join(" ");

  }
//console.log("initialQuery", initialQuery);
return initialQuery;

}


export function useTagsByKeywords(site, keywords, items) {
  const [error, setError] = useState(null);
  const [status, setStatus] = useState("loading");
  const [data, setData] = useState([]);

  //const query = string;
  //const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!keywords) return;

    //console.log("useTagsByKeywords", gimmuServerUrl);
    if (site === "auto") {
      return {data:keywordsByKeyword(keywords, items), status:'success', error:null};
    }


    const fetchData = async () => {
      const options = {
        url:
          "https://gimmu.com/webhook_772f7e04/findItemTags/?site=" + siteSlug + "&keywords=" +
          keywords,
        method: "GET",
        responseType: "json",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
/*
      const options = {
        url:
          gimmuServerUrl + "findItemTags/?site="+ defaultKeyword +"&keywords=" +
          keywords,
        method: "GET",
        responseType: "json",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
*/

      axios(options)
        .then((response) => {
          //console.log("response", response);

          if (response.data === "") {
            setData([]);
            setError(null);
            setStatus("success");
            return { data: null, error: "Empty response.", status: "error" };
          }

          let conditionedData = response.data.map((element) => {
            return element;
          });
          console.log("gimmu conditionedData", conditionedData);
          setData(conditionedData);
          setError(null);
          setStatus("success");
        })

        .catch((error) => {
          setData(null);
          setError(error);
          setStatus("error");
          return { data: null, error: error, status: "error" };
        });
    };
    fetchData();
  }, [keywords, site]);

  return { data: data, error: error, status: status };
}

export function isAlpha(str) {
  var code, i, len;

  for (i = 0, len = str.length; i < len; i++) {
    code = str.charCodeAt(i);
    if (
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123)
    ) {
      // lower alpha (a-z)
      return false;
    }
  }
  return true;
}

function stripText(text) {
  var testFoundPostTitle = text.replace(/[^a-zA-Z0-9]+/g, "");
  return testFoundPostTitle;
}

function trimAlpha(text) {
  const letters = [];
  var new_text = "";
  var flag = false;
  for (var i = 0; i < text.length; i++) {
    //       foreach (range(0, mb_strlen($text)) as $i) {
    var letter = text.substr(i, 1);

    if (isAlphaNumeric(letter)) {
      flag = true;
    }

    if (!isAlphaNumeric(letter) && flag == false) {
      letter = "";
    }

    letters.push(letter);
  }

  var new_text = "";
  var flag = false;

  var reversedLetters = [...letters].reverse();
  var processedLetters = [];
  for (let letter of reversedLetters) {
    if (isAlphaNumeric(letter)) {
      flag = true;
    }

    if (!isAlphaNumeric(letter) && flag == false) {
      letter = "";
    }

    const n = letters.length - i - 1;

    processedLetters.push(letter);
  }
  var new_text = processedLetters.join("");

  var reversedNewText = [...new_text].reverse();
  var str = reversedNewText.join("");
  return str;
}

export function isNumeric(token) {
  if (typeof token != "string") return false; // we only process strings!
  return (
    !isNaN(token) && !isNaN(parseFloat(token)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  ); // ...and ensure strings of whitespace fail
}

export function isMixed(token) {
  if (isAlphaNumeric(token) && !isAlpha(token) && !isNumeric(token)) {
    return true;
  }
  return false;
}

async function keywordsByGoogleSheet(spreadsheetId) {
  //const spreadsheetId = '...'

  const response = await fetch(
    `https://docs.google.com/spreadsheets/d/${spreadsheetId}/gviz/tq?tqx=out:json`
  );

  //const text = await response.text();
  /*
      .then(res => res.text())
      .then(text => {
          return {data:JSON.parse(text.substr(47).slice(0, -2)), status:'success', error: null};
      })
      .catch((error) => {console.log("keyword fetch failed", error);
      return {data:null, status:'error', error: error};
    })
    */
  //const text = r2d2.text();
  //console.log response.json();
  //return JSON.parse(text.substr(47).slice(0, -2));
  const json = await response.text();

  return json;
}

function keywordsByKeyword(text, items) {
  const tokens = text.split(" ");
  //console.log(tokens);

  // THIS IS WHERE THE WORK IS

  const dataset = {
    inputKeyword: "used cast iron skillet",
    outputKeywords: [
      "all that cas",
      "lodge skillets",
      "griswold iron dutch oven",
      "12 inch skillet",
    ],
  };

  const datasets = [dataset];

  datasets.map((dataset) => {
    if (dataset.inputKeyword === text) {
      return dataset.outputKeywords;
    }
  });

  return false;
}

function parseTitle(title, flag) {
  if (flag === false) {
    title = title.toLowerCase();
  }

  const arr = title.split(" ");
  const tokens = arr.map((token) => {
    var trimmedToken = trimAlpha(token); // trimAlpha
    var strippedToken = stripText(trimmedToken); // stripText
    return strippedToken;
  });

  return tokens;
}

// Sort high to low score
export function sortItemsByScore(items) {
  //if (items === undefined) {return [];}
  //if (items.length === 0) {return [];}
  const sortedItems = items.sort(function (a, b) {
    return b.score - a.score;
  });

  return sortedItems;
}

function ebayGimmuUrl(ebayUrl, item, index) {
  // console.log("ebayGimmuUrl " + ebayUrl);

  const parts = ebayUrl.split("/");

  var imageId = parts[6];

  if (parts[6] == "s-l1600.jpg") {
    imageId = parts[5];
  }

  //const imageId = parts[6];
  var slug = "merp";
  if (item) {
    slug = getSlug(item.title);
  }

  if (ebayUrl.includes("thumbs")) {
    slug = "thumbs-" + slug;
  }

  if (!ebayUrl.includes("thumbs") && ebayUrl.includes("images")) {
    slug = "images-" + slug;
  }

  const link =
  gimmuImageServerUrl + slug + "-" + imageId + "-" + index + ".jpg";

  //console.log("ebayGimmuUrl > link" + link);

  return link;

  //return "https://gimmu.com/" + getSlug(item.title) + "-" + imageId + "-" + index + ".jpg";

  //Look in json back from eBay. Get the item itemId. Use that to build the filename.
  //Got imageId = ‘vb0AAOSwOLZhS2sG’;
  //X return  https://gimmu.com/banana-train-boat-hiccip-12344348930-1.jpg.
  //return https://gimmu.com/banana-train-boat-hiccip-imageID.jpg
}

function extractItemIds(text) {
if (!text) {return false;}
  //const itemIds = [];
var ebayItemIdPattern = /\d{10,12}/g;
return text.match(ebayItemIdPattern);

//return itemIds;

}

export function extractItemId(text) {

  const itemIds = extractItemIds(text);
  if (itemIds && itemIds.length === 1) {
    return itemIds[0];
  }

  return false;
}

// function filterTitle(text) {
//   return {itemId: idTitle(text), title: stripIdFromTitle(text)};

// }

// function stripIdFromTitle(text) {
//   var filteredPostTitle = text.toLowerCase();

//   filteredPostTitle = filteredPostTitle.replace("httpsdev gimmu com", "");
//   filteredPostTitle = filteredPostTitle.replace("for sale", "");
//   filteredPostTitle = filteredPostTitle.replace("accessories", "");

//   var tokens = filteredPostTitle.split(" ");
//   var a = filteredPostTitle.split(" ");

//   var lastToken = a[a.length - 1];

//   if (isNumeric(lastToken)) {
//     // 113992540786
//     // 10000000000

//     if (lastToken > 10000000000) {
//         a.pop();
//         var filteredPostTitle = a.join(" ");

//     }
//   }

//   return filteredPostTitle;
// }

// function idTitle(text) {
//   var filteredPostTitle = text.toLowerCase();

//   var tokens = filteredPostTitle.split(" ");
//   var a = filteredPostTitle.split(" ");

//   var lastToken = a[a.length - 1];

//   if (isNumeric(lastToken)) {
//     // 113992540786
//     // 10000000000

//     if (lastToken > 10000000000) {
//       var itemId = lastToken;
//     }
//   }

//   if (itemId === undefined) {
//     itemId = null;
//   }
//   return itemId;
// }
/*
export function extractNumberTokens(text) {
  var parts = text.split(" ");
console.log("parts",parts);
  return parts.filter((part) => {
    return isNumber(part);
  });
}

var isNumber = function isNumber(value) 
{
   return typeof value === 'number' && isFinite(value);
}
*/

export const extractNumberTokens = (text, options) => {
  let numbers;
  if (!text || typeof text !== 'string') {
    return [];
  }

  numbers = text.match(/(-\d+|\d+)(,\d+)*(\.\d+)*/g);

  return numbers;
};
// ex = "[\\d]+(?:\\.[\\d]+|)(?:\\s\\d+\\/\\d+|)(?:\\s|\\'|\\\"|)[\\d]+(?:\\.[\\d]+|)(?:\\s\\d+\\/\d+|)(?:\\'|\\\"|)";

/*
const extractImperialTokens = (text, options) => {
  let feets;
  if (!text || typeof text !== 'string') {
    return [];
  }

  feets = text.match(/[\\d]+(?:\\.[\\d]+|)(?:\\s\\d+\\/\\d+|)(?:\\s|\\'|\\\"|)[\\d]+(?:\\.[\\d]+|)(?:\\s\\d+\\/\d+|)(?:\\'|\\\"|)/g);

  return feets;
};
*/



// /^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]+$/

export const extractMixedTokens = (text, options) => {
  var parts = [];
  if (Array.isArray(text)) {
    parts = text;
  } else {
    parts = text.split(" ");
  }

  return parts.filter((part) => {
    return isMixed(part);
  });

/*
  let mixeds;
  if (!text || typeof text !== 'string') {
    return [];
  }
console.log("mixeds text", text);
  mixeds = text.match(/^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]+$/g);
console.log("mixeds",mixeds);
  return mixeds;
*/
};


export function extractAlphaTokens(text) {
  var parts = text.split(" ");

  return parts.filter((part) => {
    return isAlpha(part);
  });
}

export function scoreItems(items, slugTitle) {
  if (!Array.isArray(items)) {
    return true;
  }
  //console.log("slugTitle", slugTitle);
  const scoredItems = items.map((item) => {
    return {
      ...item,
      score: scoreItem(item.title, slugTitle),
      clusterScore: scoreItem(item.title),
      siteScore: scoreItem(item.title, defaultSiteWords),
    };
  });
  return scoredItems;
}

export function scoreItem(text, clusterTokens) {
  clusterTokens = clusterTokens
    ? clusterTokens.toLowerCase().split(" ")
    : defaultKeywords.toLowerCase().split(" ");

  const titleTokens = text.toLowerCase().split(" ");

  let score = titleTokens.length;
  let count = 0;

// Test
/*
Laserjet pro p1102
LG534UA
*/
  const clusterNumberTokens = extractNumberTokens(clusterTokens.join(" "));
  const titleNumberTokens = extractNumberTokens(titleTokens.join(" "));


//extractMixedTokens

  const clusterMixedTokens = extractMixedTokens(clusterTokens.join(" "));
  const titleMixedTokens = extractMixedTokens(titleTokens.join(" "));


//console.log("text", text);

//console.log("titleMixedTokens", titleMixedTokens);
//console.log("clusterMixedTokens", clusterMixedTokens);

if ( (clusterNumberTokens !== null) && (titleNumberTokens !== null)) {
  clusterNumberTokens.map((word) => {
    if (titleNumberTokens.includes(word.toLowerCase())) {
      count += 1;
    }
    return true;
  });

  titleNumberTokens.map((word) => {
    if (clusterNumberTokens.includes(word.toLowerCase())) {
      count += 1;
    }
    return true;
  });
}

if ( (clusterMixedTokens !== null) && (titleMixedTokens !== null)) {
  clusterMixedTokens.map((word) => {
    if (titleMixedTokens.includes(word.toLowerCase())) {
      count += 5;
    }
    return true;
  });

  titleMixedTokens.map((word) => {
    if (clusterMixedTokens.includes(word.toLowerCase())) {
      count += 5;
    }
    return true;
  });
}



if (clusterTokens.length === 1) {

  if (titleTokens.includes(clusterTokens[0].toLowerCase())) {
    count += 1;
  }

}


  clusterTokens.map((word) => {
    if (titleTokens.includes(word.toLowerCase())) {
      count += 1;
    }
    return true;
  });

  titleTokens.map((word) => {
    if (clusterTokens.includes(word.toLowerCase())) {
      count += 1;
    }
    return true;
  });

  // Weight matches in first three tokens of title
  clusterTokens.map((word) => {
    if (titleTokens.length === 0) {
      return true;
    }

    if (word.toLowerCase() === titleTokens[0].toLowerCase()) {
      count += 1;
    }

    if (titleTokens.length === 1) {
      return true;
    }

    if (word.toLowerCase() === titleTokens[1].toLowerCase()) {
      count += 1;
    }

    if (titleTokens.length === 2) {
      return true;
    }

    if (word.toLowerCase() === titleTokens[2].toLowerCase()) {
      count += 1;
    }
    return true;
  });

  score = count;

  // NEXT STEP EXTRACT MIXEDs.

  //const score = LevenshteinDistance(keywords, text);
  //console.log(text, keywords, score);

  return score;
}

function deprecate_scoreItem(item, cluster = null) {
  var matchMin = matchScoreMin;
  var matchMax = matchScoreMax;

  var min = minScore;
  var max = maxScore;

  var titleTokens = parseTitle(item.title);

  item.score = 0;
  var score = 0;

  var parsedItem = item;
  var title = item.title;

  if (Array.isArray(title)) {
    title = title[0];
  }

  var descriptionWords = parseTitle(title);

  // dev here. carry on.
  score += 1;

  item.score = score;
  return item.score;
}

// https://medium.com/code-85/how-to-find-unique-values-of-a-specific-key-in-an-array-of-objects-276e28b9770d
export function uniqueItems(items) {
  //console.log("items",items);
  //const key = "id";
  //const things = items;
  const things = items.filter(
    (thing, index, self) => index === self.findIndex((t) => t.id === thing.id)
  );

  return things;
}

// Sort high to low score
function filterItemsByScore(items, minScore) {
  //console.log("items",items);
  //if (items === undefined) {return [];}
  //if (items.length === 0) {return [];}
  //return items.filter(item => (minScore !== null && item.score > minScore));
  return items;
}

export function gimmuItems(keywords, items) {
  if (!Array.isArray(items)) {
    return true;
  }
  const tokens = keywords.split(" ");
  // Drop items with variants.

  const itemsPreScored = scoreItems(items, keywords);

  const processedItemsz = itemsPreScored.filter(
    (item) => item.variants === false
  );

  // Check whether the siteScore for the item
  // is enough to suggest the item is congruent with the site.
  const processedItemsy = processedItemsz.filter((item) => {
    if (item.siteScore === undefined) {
      return false;
    }
    return item.siteScore >= minimumSiteScore;
  });

  // We only have title and categoryPath
  // Drop items which don't mention train or railroad
  //"Toys & Hobbies|Model Railroads & Trains|Railroads & Trains|Other Railroads & Trains";

  var processedItemsj = processedItemsy.filter((item) =>
    item.title.toLowerCase().includes(defaultKeyword)
  );
  var processedItemsx = processedItemsj;

  var processedItemsx = processedItemsj.filter((item) => {
    const tokens = getSlug(item.title).split("-");
    // console.log("GimmuItemList tokens", tokens);
    //return false;

    // some - true if any one item matches the condition.
    // in this case does anything in this title matches
    const hasThing = defaultNotKeywords.some((notKeyword) => {
      // console.log("item.title", item.title);
      //return true;
      return item.title.toLowerCase().includes(notKeyword);
    });
    // console.log("hasThing", hasThing);

    return !hasThing;
  });

  //var processedItemsx = processedItemsj;
  // https://stackoverflow.com/questions/23921683/javascript-move-an-item-of-an-array-to-the-front/48456512
  if (processedItemsx === undefined) {
    return;
  }

  const i = [...processedItemsx];
  const j = uniqueItems(i);

  // rescore items because we may have stale items from prior searches
  // Not any longer
  //const scoredItems = scoreItems(j);
  const scoredItems = j;
  //const sortedItems = sortItemsByScore(scoredItems);

  //var filteredItems = scoredItems;

  //console.log("scoredItems", scoredItems);

  var minScore = null;
  if (tokens.length > 1) {
    minScore = 0;
  }

  var filteredItems = filterItemsByScore(scoredItems, minScore);

  const sortedItems = sortItemsByScore(filteredItems);
  //setProcessedItems(sortedItems);
  return sortedItems;
  //setStatus('Can now see ' + sortedItems.length + ' things.');
  //setProcessedItem(item);
  //    setProcessedItems({...processedItems, ...processedItemsx});
  // Need to test this.
}

const Gimmu = { useQueryByKeywords, ebayGimmuUrl, extractItemId, extractAlphaTokens, scoreItem, scoreItems, uniqueItems, sortItemsByScore, keywordsByKeyword, useTagsByKeywords };
export { keywordsByGoogleSheet };
export default Gimmu;
