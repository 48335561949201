export  function extractTokens(text) {
    var parts = text.split(" ");

    return parts.filter((parts) => {
      return true;
    });
  }

export  function extractAlphaTokens(text) {
    var parts = text.split(" ");

    return parts.filter((part) => {
      return isAlpha(part);
    });
  }

export function isAlpha(str) {
    var code, i, len;

    for (i = 0, len = str.length; i < len; i++) {
      code = str.charCodeAt(i);
      if (
        !(code > 64 && code < 91) && // upper alpha (A-Z)
        !(code > 96 && code < 123)
      ) {
        // lower alpha (a-z)
        return false;
      }
    }
    return true;
  }
