
import React, { useCallback, useRef, useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import brokenImage from "./../images/brokenImage.png"

const useStyles = makeStyles((theme) => ({
  gridItem: {
    // Add border that contrasts lightly with background color.
    // We use boxShadow so that it's hidden around outer edge
    // due to container <Card> having overflow: hidden
    boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
    textAlign: "center",
  },
  imageContainer: {
    height:'200px',
    margin: "0 auto",
    padding:'10px',
    display:'flex',
    alignItems:'center',
    "& img": {
      width: "100%",
    },
  },
}));



function ItemThumbnail(props) {
  // const storageRef = firebase.storage().ref();
  const RETRY_COUNT = 1;
  const RETRY_DELAY = 5000;
  const showSkeleton = false;
  const classes = useStyles();
  const { item } = props;
  const [image, setImage] = useState();
  const [error, setError] = useState(false);
  const componentRef = useRef();
  const [loadingContext, setLoadingContext] = useState(true);
  const handleLoad = useCallback(() => {
    setError(false);
    setLoadingContext(false);
  }, []);

  useEffect(() => {
    componentRef.current = RETRY_COUNT;
  }, []);

  const handleError = useCallback(({ currentTarget }) => {
    setError(true);
    if (componentRef && componentRef.current && componentRef.current > 0) {
      setTimeout(() => {
        currentTarget.onerror = null;
        currentTarget.src = image;
        componentRef.current =
          componentRef && componentRef.current && componentRef.current - 1;
      }, RETRY_DELAY);
    } else {
      currentTarget.onerror = null; // prevents looping
      currentTarget.src = brokenImage;

      setLoadingContext(false);
    }
  }, []);
  // let images = (item.images === undefined ? [] : item.images)

  //  let image = (images[0] === undefined ? {location:""} : images[0])

  //let location = (image.location === undefined ? "" : image.location)
  //let image = item && item.galleryURL[0] ? item.galleryURL[0] : item.galleryURL;
  //let location = (item.images === undefined ? : item.images[0].location);
  useEffect(() => {
    if (item === undefined) {
      return;
    }
    //http://thumbs1.ebaystatic.com/pict/12345678904040_1.jpg thumbs1 (1 is a random digit) ; 1234567890 is the item# ; 4040 means 140x140 ; "_1" could be "_2" or other random#

   // const thumbnailURL =
   //   "http://thumbs1.ebaystatic.com/pict/" + item.itemId + "_1.jpg";

      const thumbnailURL = item.images[0];
      // console.log("thumbnailURL", thumbnailURL)
      console.log("image state", image)
      //setImage("http://localhost:3000/stipid.png")
    setImage(thumbnailURL);
  }, [item]);


  return (
    <>
      {/*<img src={value}  />*/}
      <Box className={classes.imageContainer}>

        {/* {image === undefined && (<Skeleton height={100} width={100} />)} */}

        {/* <img 
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = {atamazon};
        }}
       src={image} 
       alt={item.title}  style={{display:'block', maxHeight:'100%', width:'100%', objectFit:'cover'}}
        /> */}
{showSkeleton && (
<Skeleton
        variant="rect"
      //  width="100%"
       
        style={{
          display: loadingContext ? "block" : "none",
          width: "100%",
          aspectRatio: "1/1",
          height:'100%',
        
        }}
      />)}
      

      <div style={{ display: loadingContext ? "none" : "flex", height:'100%'}}>
        <img
          src={image}
          onError={handleError}
          onLoad={handleLoad}
          alt={item.title}  style={{maxHeight:'100%', width:'100%', objectFit:'contain'}}
        />
      </div>



        {/* <img src={image} alt={item.title}  style={{display:'block', maxHeight:'100%', width:'100%', objectFit:'cover'}} /> */}
      </Box>
    </>
  );
}

export default ItemThumbnail;
