import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import token from "./../images/token.png";

const { REACT_APP_SITE_TITLE, REACT_APP_WEB_PREFIX } = process.env;

function HeroSection2(props) {
  return (

      <Container>
        <Box textAlign="left">
<Typography color='primary'>

<p>PRIVACY POLICY&nbsp;&nbsp;&nbsp;&nbsp;</p>



<p>Last updated&nbsp;November 01, 2022&nbsp;&nbsp;</p>



<p>Thank you for choosing to be part of our community at&nbsp;Hobson Media LLC, doing business as&nbsp;{REACT_APP_SITE_TITLE}(“{REACT_APP_SITE_TITLE}”, “we”, “us”, or “our”). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our&nbsp;policy, or our practices with regards to your personal information, please contact us.&nbsp;</p>



<p>When you visit our&nbsp;website&nbsp;{REACT_APP_WEB_PREFIX},&nbsp;and use our services, you trust us with your personal information. We take your privacy very seriously. In this&nbsp;privacy policy, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this&nbsp;privacy policy&nbsp;that you do not agree with, please discontinue use of our&nbsp;Sites&nbsp;and our services.&nbsp;</p>



<p>This&nbsp;privacy policy&nbsp;applies to all information collected through our&nbsp;website&nbsp;(such as&nbsp;{REACT_APP_WEB_PREFIX},&nbsp;and/or any related services, sales, marketing or events (we refer to them collectively in this&nbsp;privacy policy&nbsp;as the &#8220;Services&#8221;).&nbsp;&nbsp;</p>



<p>Please read this&nbsp;privacy policy&nbsp;carefully as it will help you make informed decisions about sharing your personal information with us.&nbsp;&nbsp;&nbsp;</p>



<p><strong>TABLE OF CONTENTS&nbsp;&nbsp;</strong></p>



<p>1. WHAT INFORMATION DO WE COLLECT?</p>



<p>2. HOW DO WE USE YOUR INFORMATION?</p>



<p>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?&nbsp;&nbsp;</p>



<p>4. WHO WILL YOUR INFORMATION BE SHARED WITH?&nbsp;&nbsp;&nbsp;&nbsp;</p>



<p>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>



<p>6. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?&nbsp;&nbsp;</p>



<p>7. HOW LONG DO WE KEEP YOUR INFORMATION?&nbsp;&nbsp;&nbsp;</p>



<p>8. HOW DO WE KEEP YOUR INFORMATION SAFE?&nbsp;&nbsp;&nbsp;</p>



<p>9. DO WE COLLECT INFORMATION FROM MINORS?&nbsp;&nbsp;</p>



<p>10. WHAT ARE YOUR PRIVACY RIGHTS?&nbsp;&nbsp;</p>



<p>11. DATA BREACH&nbsp;</p>



<p>12. CONTROLS FOR DO-NOT-TRACK FEATURES&nbsp;</p>



<p>13. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?&nbsp;</p>



<p>14. DO WE MAKE UPDATES TO THIS POLICY?</p>



<p>15. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</p>



<p><strong>1. WHAT INFORMATION DO WE COLLECT?&nbsp;&nbsp;</strong></p>



<p>Personal information you disclose to us&nbsp;</p>



<p>In Short:&nbsp;We collect personal information that you provide to us such as name, address, contact information, passwords and security data,&nbsp;and payment information.</p>



<p>We collect personal information that you voluntarily provide to us when&nbsp;registering at the&nbsp;Services&nbsp;expressing an interest in obtaining information about us or our products and services, when participating in activities on the&nbsp;Services&nbsp;(such as posting messages in our online forums or entering competitions, contests or giveaways)&nbsp;or otherwise contacting us.</p>



<p>The personal information that we collect depends on the context of your interactions with us and the&nbsp;Services, the choices you make and the products and features you use. The personal information we collect can include the following:</p>



<p>Publicly Available Personal Information.&nbsp;We collect&nbsp;email addresses,&nbsp;and other similar data.</p>



<p>Credentials.&nbsp;We collect passwords, password hints, and similar security information used for authentication and account access.&nbsp;&nbsp;&nbsp;</p>



<p>All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.&nbsp;&nbsp;&nbsp;</p>



<p>Information automatically collected&nbsp;</p>



<p>In Short:&nbsp;Some information – such as IP address and/or browser and device characteristics – is collected automatically when you visit our&nbsp;Services.</p>



<p>We automatically collect certain information when you visit, use or navigate the&nbsp;Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our&nbsp;Services&nbsp;and other technical information. This information is primarily needed to maintain the security and operation of our&nbsp;Services, and for our internal analytics and reporting purposes.</p>



<p>Like many businesses, we also collect information through cookies and similar technologies.&nbsp;&nbsp;&nbsp;</p>



<p><strong>Information collected from other sources&nbsp;</strong></p>



<p>In Short:&nbsp;&nbsp;We may collect limited data from public databases, marketing partners,&nbsp;and other outside sources.&nbsp;&nbsp;</p>



<p>We may obtain information about you from other sources, such as public databases, joint marketing partners,&nbsp;as well as from other third parties. Examples of the information we receive from other sources include: social media profile information; marketing leads and search results and links, including paid listings (such as sponsored links). We will inform you about the source of information and the type of information we have collected about you within a reasonable period after obtaining the personal data, but at the latest within one month.</p>



<p><strong>2. HOW DO WE USE YOUR INFORMATION?&nbsp;&nbsp;</strong></p>



<p>In Short:&nbsp;&nbsp;We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.&nbsp;&nbsp;</p>



<p>We use personal information collected via our&nbsp;Services&nbsp;for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.&nbsp;</p>



<p><strong>We use the information we collect or receive:&nbsp;</strong></p>



<p>To facilitate account creation and logon process.&nbsp;If you choose to link your account with us to a third party account (such as your Google or Facebook account), we use the information you allowed us to collect from those third parties to facilitate account creation and logon process for the performance of the contract.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

</p>



<p>To send you marketing and promotional communications.&nbsp;We and/or our third party marketing partners may use the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt-out of our marketing emails at any time (see the &#8220;WHAT ARE YOUR PRIVACY RIGHTS&#8221; below).&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>



<p><strong>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?&nbsp;&nbsp;</strong></p>



<p>In Short:&nbsp;&nbsp;We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.&nbsp;&nbsp;&nbsp;</p>



<p>We may process or share data based on the following legal basis:</p>



<p>Consent:&nbsp;We may process your data if you have given us specific consent to use your personal information in a specific purpose.&nbsp;

</p>



<p>Legitimate Interests:&nbsp;We may process your data when it is reasonably necessary to achieve our legitimate business interests.&nbsp;

</p>



<p>Performance of a Contract:&nbsp;Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.&nbsp;

</p>



<p>Legal Obligations:&nbsp;We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).&nbsp;

</p>



<p>Vital Interests:&nbsp;We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</p>



<p>More specifically, we may need to process your data or share your personal information in the following situations:&nbsp;&nbsp;&nbsp;</p>



<p>Vendors, Consultants and Other Third-Party Service Providers.&nbsp;We may share your data with third party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the&nbsp;Services, which will enable them to collect data about how you interact with the&nbsp;Services&nbsp;over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content and better understand online activity. Unless described in this Policy, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

</p>



<p>Business Transfers.&nbsp;We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.&nbsp;&nbsp;&nbsp;&nbsp;

</p>



<p>Affiliates.&nbsp;We may share your information with our affiliates, in which case we will require those affiliates to honor this&nbsp;privacy policy. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

</p>



<p>Business Partners.&nbsp;We may share your information with our business partners to offer you certain products, services or promotions.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

</p>



<p>Other Users.&nbsp;When you share personal information&nbsp;(for example, by posting comments, contributions or other content to the&nbsp;Services)&nbsp;or otherwise interact with public areas of the&nbsp;Services, such personal information may be viewed by all users and may be publicly distributed outside the&nbsp;Services&nbsp;in perpetuity.&nbsp;Similarly, other users will be able to view descriptions of your activity, communicate with you within our&nbsp;Services, and view your profile.&nbsp;&nbsp;</p>



<p><strong>4. WHO WILL YOUR INFORMATION BE SHARED WITH?&nbsp;&nbsp;</strong></p>



<p>In Short:&nbsp;&nbsp;We only share information with the following third parties.&nbsp;&nbsp;</p>



<p>We only share and disclose your information with the following third parties. We have categorized each party so that you may be easily understand the purpose of our data collection and processing practices. If we have processed your data based on your consent and you wish to revoke your consent, please contact us.&nbsp;&nbsp;</p>



<p>Advertising, Direct Marketing, and Lead Generation&nbsp;
Google AdSense</p>



<p>Affiliate Marketing Programs&nbsp;
Amazon Affiliation&nbsp;and&nbsp;eBay Partner Network</p>



<p>Content Optimization&nbsp;&nbsp;
Google Site Search</p>



<p>Web and Mobile Analytics&nbsp;
Google Analytics</p>



<p><strong>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?&nbsp;</strong>&nbsp;</p>



<p>In Short:&nbsp;&nbsp;We may use cookies and other tracking technologies to collect and store your information.&nbsp;&nbsp;</p>



<p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information.</p>



<p>Cookies are small text files a web site places on your computer&#8217;s hard drive to recognize repeat users and facilitate the user&#8217;s use of the site. Also, cookies allow a site to track usage behavior and compile aggregate data for content improvement, targeted advertising, and marketing and promotional purposes. Users should be aware that {REACT_APP_SITE_TITLE} cannot control the use of cookies or the resulting information by advertisers or other third parties. Most browsers allow a user to deny cookies; however, users should note that cookies may be required to provide certain web site features.</p>



<p><strong>6. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?&nbsp;&nbsp;</strong></p>



<p>In Short:&nbsp;&nbsp;We are not responsible for the safety of any information that you share with third-party providers who advertise, but are not affiliated with, our websites.&nbsp;&nbsp;&nbsp;</p>



<p>The&nbsp;Services&nbsp;may contain advertisements from third parties that are not affiliated with us and which may link to other websites, online services or mobile applications. We cannot guarantee the safety and privacy of data you provide to any third parties. Any data collected by third parties is not covered by this&nbsp;privacy policy. We are not responsible for the content or privacy and security practices and policies of any third parties, including other websites, services or applications that may be linked to or from the&nbsp;Services&nbsp;. You should review the policies of such third parties and contact them directly to respond to your questions.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>



<p><strong>7. HOW LONG DO WE KEEP YOUR INFORMATION?&nbsp;&nbsp;</strong></p>



<p>In Short:&nbsp;We keep your information for as long as necessary to fulfill the purposes outlined in this&nbsp;privacy policy&nbsp;unless otherwise required by law.&nbsp;&nbsp;&nbsp;</p>



<p>We will only keep your personal information for as long as it is necessary for the purposes set out in this&nbsp;privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this policy will require us keeping your personal information for longer than&nbsp;the period of time in which users have an account with us.&nbsp;&nbsp;</p>



<p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.&nbsp;&nbsp;&nbsp;</p>



<p><strong>8. HOW DO WE KEEP YOUR INFORMATION SAFE?&nbsp;&nbsp;&nbsp;</strong></p>



<p>In Short:&nbsp;&nbsp;We aim to protect your personal information through a system of organizational and technical security measures.&nbsp;&nbsp;</p>



<p>We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your personal information, transmission of personal information to and from our&nbsp;Services&nbsp;is at your own risk. You should only access the services within a secure environment.&nbsp;&nbsp;</p>



<p><strong>9. DO WE COLLECT INFORMATION FROM MINORS?&nbsp;&nbsp;</strong></p>



<p>In Short:&nbsp;&nbsp;We do not knowingly collect data from or market to children under 18 years of age.&nbsp;&nbsp;</p>



<p>We do not knowingly solicit data from or market to children under 18 years of age. By using the&nbsp;Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the&nbsp;Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we have collected from children under age 18, please contact us.&nbsp;&nbsp;</p>



<p><strong>10. WHAT ARE YOUR PRIVACY RIGHTS?&nbsp;&nbsp;</strong></p>



<p>In Short:&nbsp;&nbsp;You may review, change, or terminate your account at any time.</p>



<p>If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here:&nbsp;http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.&nbsp;
&nbsp;</p>



<p><strong>Account Information&nbsp;&nbsp;</strong></p>



<p>If you would at any time like to review or change the information in your account or terminate your account, please contact us.&nbsp;</p>



<p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>



<p>Cookies and similar technologies:&nbsp;Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our&nbsp;Services. To opt-out of interest-based advertising by advertisers on our&nbsp;Services&nbsp;&nbsp;visit&nbsp;http://www.aboutads.info/choices/.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>



<p>Opting out of email marketing:&nbsp;You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list – however, we will still need to send you service-related emails that are necessary for the administration and use of your account. To otherwise opt-out, please contact us.&nbsp;</p>




<p><strong>11. DATA BREACH&nbsp;&nbsp;</strong></p>



<p>A privacy breach occurs when there is unauthorized access to or collection, use, disclosure or disposal of personal information. You will be notified about data breaches when&nbsp;Hobson Media LLC&nbsp;believes you are likely to be at risk of serious harm. For example, a data breach may be likely to result in serious financial harm or harm to your mental or physical well-being. In the event that&nbsp;Hobson Media LLC&nbsp;becomes aware of a security breach which has resulted or may result in unauthorized access, use or disclosure of personal information&nbsp;Hobson Media LLC&nbsp;will promptly investigate the matter and notify the applicable Supervisory Authority not later than 72 hours after having become aware of it, unless the personal data breach is unlikely to result in a risk to the rights and freedoms of natural persons.</p>



<p><strong>12. CONTROLS FOR DO-NOT-TRACK FEATURES&nbsp;&nbsp;</strong></p>



<p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this&nbsp;privacy policy.&nbsp;&nbsp;</p>



<p><strong>13. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?&nbsp;</strong>&nbsp;</p>



<p>In Short:&nbsp;&nbsp;Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.&nbsp;&nbsp;&nbsp;</p>



<p>California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.&nbsp;</p>



<p>If you are under 18 years of age, reside in California, and have a registered account with the&nbsp;Services, you have the right to request removal of unwanted data that you publicly post on the&nbsp;Services. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the&nbsp;Services, but please be aware that the data may not be completely or comprehensively removed from our systems.</p>



<p><strong>14. DO WE MAKE UPDATES TO THIS POLICY?&nbsp;&nbsp;</strong></p>



<p>In Short:&nbsp;&nbsp;Yes, we will update this policy as necessary to stay compliant with relevant laws.&nbsp;&nbsp;</p>



<p>We may update this&nbsp;privacy policy&nbsp;from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this&nbsp;privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this&nbsp;privacy policy&nbsp;frequently to be informed of how we are protecting your information.&nbsp;&nbsp;</p>



<p><strong>15. HOW CAN YOU CONTACT US ABOUT THIS POLICY?&nbsp;</strong>&nbsp;</p>



<p>If you have questions or comments about this policy, please contact us: info@hobson-media.com</p>


{/* 
<img src={token} /> */}
</Typography>   
        </Box>
      </Container>

  );
}

export default HeroSection2;
