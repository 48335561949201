import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { useWatch } from "react-hook-form";
import { formatPrice } from "./../util/text.js";
import { Button, Typography, Grid, Fade } from "@material-ui/core";
import ImageCarousel from "./../components/ImageCarousel";
import Skeleton from "@material-ui/lab/Skeleton";
import EbayItemButton from "./../components/EbayItemButton";
import AmazonItemButton from "./../components/AmazonItemButton";
import { useItemByItemId } from "./../util/ebay";
import LinearProgress from "@material-ui/core/LinearProgress";

const { REACT_APP_SITE_TITLE, REACT_APP_KEYWORD } = process.env;

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },

  name: {
    overflow: "hidden",
    display: "-webkit-box",
    // minHeight: "50px",
    WebkitLineClamp: 10,
    WebkitBoxOrient: "vertical",
  },
  colorPrimary: {
    backgroundColor: "#e91e1e",
    height: 20,
    borderRadius: 5,
  },
  barColorPrimary: {
    backgroundColor: "#4caf50",
    height: 20,
    borderRadius: 5,
  },
}));

function ItemPanel(props) {
  const { filter } = props;
  var { flavour } = props;

  var { item } = props;

  const {
    data: fullItem,
    status: itemStatus,
    error: itemError,
  } = useItemByItemId(item.id);

  const [currentItem, setCurrentItem] = useState(item);

  // This gets run when item changes.
  useEffect(() => {
    //console.log("ItemPanel fullItem", fullItem);
  }, [fullItem]);

  useEffect(() => {
    //console.log("ItemPanel itemStatus", itemStatus);
  }, [itemStatus]);

  useEffect(() => {
    //console.log("ItemPanel itemError", itemError);
  }, [itemError]);

  // This gets run when item changes.

  useEffect(() => {
    // console.log("ItemPanel item id" , item.id);
  }, [item]);

  var { flavour } = props;

  const classes = useStyles();

  const keywords = "phone";

  if (fullItem === null) {
    return null;
  }

  if (itemStatus === "loading") {
    return (
      <>
   
          <Skeleton
            variant="rect"
            width="100%"
            height={350}
            style={{ marginBottom: "3em" }}
          />
 
      </>
    );
  }

  if (itemStatus !== "success") {
    return "NOT SUCCESS";
  }

  if (itemError !== null) {
    return <>COULD NOT GET ITEM DETAILS</>;
  }

  if (fullItem === undefined) {
    return <>LOADING ITEM DETAILS</>;
  }

  if (!fullItem.title.toLowerCase().includes(REACT_APP_KEYWORD)) {
    return "ITEM NOT LIONEL";
  }

  return (
    <>
      <Grid container spacing={2}>
  
          <Grid item xs={12}>
   
              <Box style={{ display: "block"}}>
                <ImageCarousel item={fullItem} />
              </Box>
   
          </Grid>

        <Grid item xs={12} sm={5}>
          <AmazonItemButton item={fullItem} />
        </Grid>
        <Grid item xs={12} sm={5}>
          <EbayItemButton item={fullItem} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5">{fullItem.Title}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" style={{ fontWeight: 600 }}>
            {fullItem.CurrentPrice.CurrencyID}{" "}
            {formatPrice(fullItem.CurrentPrice.Value)}{" "}
          </Typography>
        </Grid>

        {/*fullItem.itemId*/}

        <Grid item xs={12}>
          {/*fullItem.itemId*/}

          <Typography variant="body1" className={classes.name}>
            <b> Description:</b> <br />
            {fullItem.shortDescription}
          </Typography>
          <br />
          <Typography variant="body1" className={classes.name}>
            <b>Condition:</b>
            <br /> {fullItem.condition}
          </Typography>
          <br />

          <Typography variant="body1" className={classes.name}>
            <b>Location:</b> <br />
            {fullItem.itemLocation.city}, {fullItem.itemLocation.country}
          </Typography>
          <br />

          <Typography variant="body1" className={classes.name}>
            <b>Category:</b> <br />
            {fullItem.categoryPath}
          </Typography>
          <br />
        </Grid>
      </Grid>

      {true && <></>}
    </>
  );
}

export default ItemPanel;
