export function getSlug(text) {
  if (text === undefined) {
    return "";
  }
  if (text === null) {
    return "";
  }

  const rawSlug = text
    .toLowerCase()
    .replace("+", " ")
    .replace("-", " ")
    .replace(/[^\w ]+/g, " ")
    .replace(/ +/g, "-");

  const first = rawSlug.charAt(0);
  const last = rawSlug.charAt(rawSlug.length - 1);
  var conditionedSlug = rawSlug;
  conditionedSlug = conditionedSlug.replace(/-+$/, "");

  return conditionedSlug;
}

// Change this to be the same as above.
export function getPositiveSlug(text) {
  if (text === undefined) {return ""};
  if (text === null) {return ""};
//  return text.toLowerCase().replace(/\s+/g,"-");
//  return slugify(text,"-").toLowerCase();
  return text
             .toLowerCase()
             .replace(/[^\w ]+/g, '')
             //change the below - to a + for Amazon. MT
             .replace(/ +/g, '+');
}
