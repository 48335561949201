

export const getFirebaseTime = true;

  function getAgeMinutes(bid) {

    const milliseconds = age(bid);
    const minutes = Math.floor(milliseconds / (60 * 1000));
    
    return minutes;
    
    }
    
    
    function  firebaseTime() {return null;}


const dayOrdinal = function (d) {
  if (d > 3 && d < 21) return d + "th";
  switch (d % 10) {
    case 1:
      return d + "st";
    case 2:
      return d + "nd";
    case 3:
      return d + "rd";
    default:
      return d + "th";
  }
};

export function headlineEventPeriod(event) {
  console.log("headline Event Period");
  const endAtEpoch = Date.parse(event.endTime);
  var endAt = new Date(endAtEpoch);

  const startAtEpoch = Date.parse(event.startTime);
  var startAt = new Date(startAtEpoch);
  const startDay = startAt.toLocaleDateString(undefined, { day: "numeric" });
  const startMonth = startAt.toLocaleDateString(undefined, { month: "long" });
  const startYear = startAt.toLocaleDateString(undefined, { year: "numeric" });

  const endDay = endAt.toLocaleDateString(undefined, { day: "numeric" });
  const endMonth = endAt.toLocaleDateString(undefined, { month: "long" });
  const endYear = endAt.toLocaleDateString(undefined, { year: "numeric" });

  var day = dayOrdinal(startDay);
  if (startDay === endDay) {
    day = dayOrdinal(startDay);
  } else {
    day = dayOrdinal(startDay) + "-" + dayOrdinal(endDay);
  }

  var time = dayOrdinal(day) + " " + startMonth;
  if (startMonth === endMonth) {
    time = day + " " + startMonth;
  } else {
    time =
      dayOrdinal(startDay) +
      " " +
      startMonth +
      " - " +
      dayOrdinal(endDay) +
      " " +
      endMonth;
  }

  if (startYear === endYear) {
    time = time + " " + startYear;
  } else {
    time =
      dayOrdinal(startDay) +
      " " +
      startMonth +
      " " +
      startYear +
      " - " +
      dayOrdinal(endDay) +
      " " +
      endMonth +
      " " +
      endYear;
  }

  return time;
}

// at firebase object
export function age(at) {

  if (at === null) {return null }

return (Date.now() - at.seconds *1000)

}
// at firebase object
export function ageMinutes(at) {

  const milliseconds = age(at);
  const minutes = Math.floor(milliseconds / (60 * 1000));
  
  return minutes;
  
  }

  export function humanAge(at) {

var milliseconds = age(at);
var seconds = Math.floor(milliseconds/1000);


if (seconds > 60 * 60) {
return humanTime(firebaseTime(at));
}

if (seconds > 60) {
return Math.floor(seconds/60) + " minutes ago.";
}
return Math.floor(seconds) + " seconds ago.";

  }

export function zuluTime(at) {
  var epochtime = Date.parse(at);

  var d = new Date(epochtime);
  return d.toISOString();
}

export function humanTime(at) {
  if (at === undefined) {return "Not available"}
  var epochtime = Date.parse(at);

  var d = new Date(epochtime);
  //return d.toLocaleString([], {timeStyle: 'short'});
  return d.toLocaleString().replace(/:\d{2}\s/, " ");
}

export function humanMonthYearTime(at) {
  var d = new Date();
  if (at !== undefined) {
    var epochtime = Date.parse(at);
    d = new Date(epochtime);
  }
var month = d.toLocaleDateString('en-US', { month: 'long'});
var year = d.getFullYear();

  //return d.toLocaleString([], {timeStyle: 'short'});
  return month + " " + year;
}


// Alternative formats
/*
d.toLocaleString([], {timeStyle: 'short'});
*/
/*
d.toLocaleString().replace(/:\d{2}\s/,' ');
*/
export function addMinute(at) {
  var tempLotEndTime = at;

  if (tempLotEndTime === undefined) {
    return true;
  }

  var epochtime = Date.parse(tempLotEndTime);

  // Extend lot end by 1 minute.
  epochtime = epochtime + 60 * 1000;

  var d = new Date(epochtime);
  return d.toISOString();
}

export function isPastTime(at) {
  var epochTime = Date.parse(at);
  var currentTime = Date.now();

  if (currentTime - epochTime > 0) {
    // The time given is in the past
    return true;
  }
  return false;
}

export function secondsDifference(timeA, timeB) {
  var epochTimeA = Date.parse(timeA);
  var epochTimeB = Date.parse(timeB);

  var millisecondsDifference = epochTimeA - epochTimeB;
  var secondsDifference = (millisecondsDifference / 1000).toFixed(0);
  return secondsDifference;
}

export function millisecondsDifference(timeA, timeB) {
  var epochTimeA = Date.parse(timeA);
  var epochTimeB = Date.parse(timeB);

  var millisecondsDifference = epochTimeA - epochTimeB;
  //var secondsDifference = (millisecondsDifference / 1000).toFixed(0);
  return millisecondsDifference;
}
/*
export function getFirebaseCurrentTime() {
  var epochtime = Date.now();
  var d = new Date(epochtime);
  return d.toISOString();
}
*/
/*
function setMomentOffset(serverTime) {
  var offset = new Date(serverTime).getTime() - Date.now();
  moment.now = function() {
    return offset + Date.now();
  }
}

*/
export function getFirebaseCurrentTime(updateFlag) {
  return null;

}

/*
export function getFirebaseCurrentTime() {
  var epochtime = Date.now();
  var d = new Date(epochtime);
  return d.toISOString();
}
*/

// The function is intended to be an offset computed time to correspond to Firebase time.
/*
export function useFirebaseCurrentTime() {
  var epochtime = Date.now();
  var d = new Date(epochtime);
  return d.toISOString();
}
*/
export function countdownTime(at) {

  const currentTime = getFirebaseCurrentTime();
  var t = secondsDifference(at, currentTime);

  if (t>=(24*60*60)) {return humanTime(at);}

  return secondsToReadableString(t);

}

export function timetogoTime(at) {

  const currentTime = getFirebaseCurrentTime();
  var t = secondsDifference(at, currentTime);

  //if (t>=(24*60*60)) {return humanTime(at);}

  return secondsToReadableString(t);

}

export function secondsToReadableString(seconds) {
  seconds = seconds || 0;
  seconds = Number(seconds);
  seconds = Math.abs(seconds);

  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);
  const parts = [];

  if (d > 0) {
    parts.push(d + " day" + (d > 1 ? "s" : ""));
  }

  if (h > 0) {
    parts.push(h + " hour" + (h > 1 ? "s" : ""));
  }

  if (h === 0 && d > 0) {
    parts.push(h + " hour" + (h > 1 ? "s" : ""));
  }

  if (m > 0) {
    parts.push(m + " minute" + (m > 1 ? "s" : ""));
  }

  if (m === 0 && (h > 0 || d > 0)) {
    parts.push(m + " minute" + (m > 1 ? "s" : ""));
  }

  if (s > 0 && d === 0) {
    parts.push(s + " second" + (s > 1 ? "s" : ""));
  }

  if (s === 0 && d === 0 && (m > 0 || h > 0 || d > 0)) {
    parts.push(s + " second" + (s > 1 ? "s" : ""));
  }

  return parts.join(", ");
}
