import React, { useState, useEffect } from "react";
import { Box, Divider, Typography, LinearProgress, CircularProgress, Grid } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import ItemsGrid from "./../components/ItemsGrid";
import Container from "@material-ui/core/Container";
import ItemPanel from "./../components/ItemPanel";
import { useItemsByKeywords } from "./../util/ebay";
import Keywords from "./../components/Keywords";
import { getSlug } from "./../util/slug.js";
import { extractMixedTokens, extractNumberTokens, isMixed, isNumeric, isAlpha, isAlphanumeric } from "./../util/gimmu.js";
import { extractTokens, extractAlphaTokens } from "./../util/token.js";
import Skeleton from "@material-ui/lab/Skeleton";
import SearchHeader from "./../components/SearchHeader";
import PriceMetrics from "./../components/PriceMetrics";
import Stack from "./../components/Stack";

import ListSkeleton from "./ListSkeleton";
import {stopwords, stripStopwords} from "./../util/text.js"

// No longer needed?
//import words from "./../words.js";

import {} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
// import Meta from "./../components/Meta";

import { useRouter } from "./../util/router.js";

import Gimmu, { scoreItem, keywordsByKeyword } from "./../util/gimmu";

const {
  REACT_APP_KEYWORDS,
  REACT_APP_KEYWORD,
  REACT_APP_TESTPHRASE,
  REACT_APP_SITE_TITLE,
  REACT_APP_SEARCH,
  REACT_APP_NOTKEYWORDS,
  REACT_APP_DESCRIPTION,
  REACT_APP_SKIPNOTKEYWORDFILTER,
  REACT_APP_SKIPDEFAULTKEYWORDFILTER,
  REACT_APP_SHOW_ITEM_PANEL,
  REACT_APP_SHOW_PRICE_METRICS,
  REACT_APP_DEBUG,
  REACT_APP_PERSIST_ITEMS, // true/false - if true use items in browser
  REACT_APP_MINIMUM_ITEMS,
  REACT_APP_MINIMUM_SCORE
} = process.env;

const skipDefaultKeywordFilter =
  REACT_APP_SKIPNOTKEYWORDFILTER.toLowerCase() === "true"; // skips
const skipNotKeywordFilter =
  REACT_APP_SKIPDEFAULTKEYWORDFILTER.toLowerCase() === "true"; // skips  REACT_APP_SKIPDEFAULTKEYWORDFILTER
const itemPanelFlag = REACT_APP_SHOW_ITEM_PANEL.toLowerCase() === "true";
const persistItems = REACT_APP_PERSIST_ITEMS.toLowerCase() === "true";

const debugFlag = REACT_APP_DEBUG.toLowerCase() === "true";

const showPriceMetrics = REACT_APP_SHOW_PRICE_METRICS.toLowerCase() === "true";

const envItemsMinimum = parseInt(process.env.REACT_APP_MINIMUM_ITEMS || "");
const itemsMinimum = Number.isInteger(envItemsMinimum) ? envItemsMinimum : 20;

const envSearchInterval = parseInt(process.env.REACT_APP_SEARCH_INTERVAL || "");
const searchInterval = Number.isInteger(envSearchInterval)
  ? envSearchInterval
  : 5000;
//const searchInterval = 5000;

const searchReportFlag = debugFlag; // Link to debug flag for now.
//const searchReportFlag = true;

const maximumAllowedQueries = 3;

const showKeywords = false; //Do not show clusters

const defaultWord = "";

// Set up defaults

const defaultKeyword = REACT_APP_KEYWORD ? REACT_APP_KEYWORD : defaultWord;
const defaultKeywords = REACT_APP_KEYWORDS ? REACT_APP_KEYWORDS : defaultWord;

const defaultNotKeywords = REACT_APP_NOTKEYWORDS.split(", ");

//const minimumSiteScore = 1;

const envMinimumScore = parseInt(process.env.REACT_APP_MINIMUM_SCORE || "");
const minimumScore = Number.isInteger(envMinimumScore)
  ? envMinimumScore
  : 1;

console.log("minimumScore", minimumScore);

function GimmuItemList(props) {
  const text = props.event.text;

  const [countPops, setCountPops] = useState(0);

  const [failCount, setFailCount] = useState(10);

  const keywords =
    text === undefined ? defaultKeywords : text.replace(/-/g, " ");
  var { flavour } = props;

  const [processedItem, setProcessedItem] = useState();
  const [processedItems, setProcessedItems] = useState([]);

  const [tokens, setTokens] = useState([]);

  const itemId = Gimmu.extractItemId(keywords);

  const [item, setItem] = useState({ id: itemId });

  const [query, setQuery] = useState(defaultKeyword);

  const [queryStack, setQueryStack] = useState([]); // Track available queries.
  const [queryHistoryStack, setQueryHistoryStack] = useState([]); // Track requested queries

  const [index, setIndex] = useState(0);

  const [status, setStatus] = useState({
    count: 0,
    itemState: "searching",
    itemsState: "searching",
    message: "Starting search.",
  });

  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = useItemsByKeywords(query);

  useEffect(() => {
    if (tokens === undefined) {
      return;
    }
    if (tokens.length === 0) {
      return;
    }
    initQueries(tokens);
  }, [tokens]);

  useEffect(() => {
    console.log("GimmuItemList query", query);

    const newStatus = status;
    const count = newStatus.count + 1;

    if (processedItems.length < itemsMinimum) {
      setStatus({
        ...status,
        itemsState: "searching",
      });
    }
  }, [query]);

  // Developmental.
  // Haven't got this working yet.
  // Currently using a timed interval - which may be the better solution.
  useEffect(() => {
    console.log(
      "GimmuItemList.js hook action prompt",
      items && items.length,
      itemsStatus,
      itemsError,
      queryStack,
      processedItems && processedItems.length
    );

    if (itemsStatus === "success" && processedItems.length < itemsMinimum) {
      console.log("blip call for more items");
      //getNextQuery();
    }

    //if (itemsStatus !== 'loading') {
    //getNextQuery();
    //}
  }, [items, itemsStatus, itemsError, queryStack, processedItems]);

  useEffect(() => {
    setStatus({
      ...status,
      count: index,
    });
  }, [index]);

  useEffect(() => {
    if (keywords === undefined) {
      return;
    }

    console.log("GimmuItemList keywords", keywords);
    const flagFilter = true;

    if (!persistItems) {
      setProcessedItems([]);

      setStatus({
        ...status,
        message: "Cleared items.",
      });
    }
    setQueryStack([]);
    setQueryHistoryStack([]);
    setIndex(0);

    setStatus({
      ...status,
      message: "Reset query stack.",
    });

    // Create the first query.
//    const textProcessedTokens = bestFourTokens(keywords);
//    console.log("GimmuItemList textProcessedTokens", textProcessedTokens);
//    addQuery(textProcessedTokens);

    setTokens(keywords.split(" "));

    const itemId = Gimmu.extractItemId(keywords);

    if (item && itemId !== item.id) {
      setItem({ ...item, id: itemId });
    }

  }, [keywords]);

  function bestFourTokens(inputText) {
    //const s = stopwords();
    const text = stripStopwords(inputText);
//console.log('GimmuItemList stopwords:', text, "--", inputText)
    const excludeTokens = ['sale', 'for', 'value'];

    //const keywords = text;
    var lastToken = tokens[tokens.length - 1];
    var alphaTokens = extractAlphaTokens(text);
    alphaTokens = alphaTokens.filter((alphaToken)=>{
      if (excludeTokens.includes(alphaToken.toLowerCase())) {return false;}
      return true;
    })
    const numberTokens = extractNumberTokens(inputText);
  
    console.log("numberTokens", text, numberTokens)
    const mixedTokens = extractMixedTokens(inputText);

    var appendTokens = [];

    if (mixedTokens.length > 0) { 
      //appendTokens.push(mixedTokens[0]);
      appendTokens = addArray(appendTokens, mixedTokens[0])
     }

    var maximumNumberToken = 0;
    numberTokens && numberTokens.map((numberToken)=> {
      const intNumberToken = parseInt(numberToken);
      if (intNumberToken> maximumNumberToken) {maximumNumberToken = numberToken;}
    });

    const intMaximumNumberToken = parseInt(maximumNumberToken);

 if (intMaximumNumberToken > 99) {

if (mixedTokens && mixedTokens.length > 0 && mixedTokens[0].includes(maximumNumberToken)) {} else {
  //appendTokens.push(maximumNumberToken);
  appendTokens = addArray(appendTokens, maximumNumberToken )
 }
 }

if ((isNumeric(lastToken) || isAlpha(lastToken)) && tokens.length > 4) {

  if (excludeTokens.includes(lastToken)) {
  } else {

  //appendTokens.push(lastToken);
  appendTokens = addArray(appendTokens, lastToken)
}
}



 //   if (mixedTokens.length > 0 && tokens.length > 1) { 
 //     textProcessedTokens = (textProcessedTokens > 4 ? textProcessedTokens.split(' ').pop() : textProcessedTokens) + " " + mixedTokens[0];         
 //   }
 


//console.log("bestFourTokens" , alphaTokens, numberTokens, mixedTokens)
    const processedTokens = alphaTokens.slice(0, 4 - appendTokens.length);

    console.log("bestFourTokens" , "alphaTokens", alphaTokens, "numbersTokens", numberTokens, "mixedTokens", mixedTokens, "processedTokens", processedTokens, "appendTokens", appendTokens)

    var textProcessedTokens = processedTokens.join(" ");

textProcessedTokens = textProcessedTokens + " " + appendTokens.join(" ");

    return textProcessedTokens;
  }

  useEffect(() => {
    //    setStatus({ ...status, itemsState: "searching" });
  }, [query, setQuery]);

  useEffect(() => {
    console.log("item ", item);

    if (item && item.id) {
      setStatus({ ...status, itemState: "waiting" });
      return;
    }

    setStatus({ ...status, itemState: "searching" });
  }, [item, setItem]);

  // Ideally this would be in the shared code.
  // Watch for divergence.

  // Sort high to low score
  function sortItemsByScore(items) {
    const sortedItems = items.sort(function (a, b) {
      return b.score - a.score;
    });
    return sortedItems;
  }

  // Sort high to low score
  function filterItemsByScore(items, minScore) {
    //if (items === undefined) {return [];}
    //if (items.length === 0) {return [];}
    //return items.filter(item => (minScore !== null && item.score > minScore));
    return items;
  }

  useEffect(() => {
    console.log("GimmuItemList queryStack", queryStack);
  }, [queryStack]);

  function getNextQuery() {
    console.log("GimmuItemList getNextQuery called");

  

    if (queryStack.length === 0) {
      setStatus({
        ...status,
        itemsStatus: "waiting",
        message: "Query stack empty.",
      });

      return;
    }

    if (index >= maximumAllowedQueries && processedItems.length > 0) {
      setStatus({
        ...status,
        itemsStatus: "waiting",
        message: "Exceeded maximum allowed queries.",
      });

      return;
    }

    const q = popQuery();

    console.log("GimmuItemList getNextQuery index", index);
    console.log(
      "GimmuItemList getNextQuery queryStack[" + index + "]",
      queryStack[index]
    );
    console.log("GimmuItemList getNextQuery queryStack", queryStack);

    const t = [defaultKeyword, q.tokens].join(" ");
    console.log("GimmuItemList getNextQuery setQuery t", t);


    // Check to make sure this is not a search that has
    // already been tried.
    const checkTokens = (obj) => obj.tokens === q.tokens;
    const inStack = queryHistoryStack.some(checkTokens);

    if (inStack) {
      console.log("GimmuItemList already queried", q.tokens);
    } else {

      setQuery(t);

      setStatus({
        ...status,
        itemsStatus: "searching",
        message: "Query set to " + t + ".",
      });

      setIndex(index + 1);
    }

  }

  /*
Functions here to manage the query stack.
Move them out to query.js when refactoring.
*/

  function addQuery(inputTokens) {

   

    if (inputTokens === undefined) {
      return;
    }

    if (inputTokens.trim() === "") {
      return;
    }
const tokens = inputTokens.trim();

    const newQuery = { tokens: tokens };

    const checkTokens = (obj) => obj.tokens === tokens;
    setQueryStack((queryStack) => {

      const inStack = queryStack.some(checkTokens);
      console.log("GimmuItemList addQuery tokens", tokens, "inStack", inStack);
      if (!inStack) {
        return [newQuery, ...queryStack];
      }
      return [...queryStack];
    });
  }

  function addArray(arr, element) {

    if (element === undefined) {
      return;
    }

    if (element.trim() === "") {
      return;
    }

    //const newQuery = { tokens: tokens };

    const checkTokens = (obj) => obj === element;
    //setQueryStack((queryStack) => {

      const inStack = arr.some(checkTokens);
      //console.log("GimmuItemList addQuery tokens", tokens, "inStack", inStack);
      if (!inStack) {
        //return [element, ...arr];
        return [...arr, element];
      }
      return [...arr];
    //});
  }

  function popQuery() {
    setCountPops((countPops) => countPops + 1);
    const newQueryStack = [...queryStack];

    // Pop from the end.
    const poppedQuery = newQueryStack.pop();

    // Or pop from the beginning.
    //const poppedQuery = newQueryStack.shift();

    setQueryStack([...newQueryStack]);

    console.log("GimmuItemList getNextQuery popQuery poppedQuery", poppedQuery);

    const checkTokens = (obj) => obj.tokens === tokens;
    setQueryHistoryStack((queryStack) => {
      return [poppedQuery, ...queryHistoryStack];
    });

    return poppedQuery;
  }

  function initQueries(tokens) {

    // The idea here is that using simple addQuery commands,
    // the front end developer can develop custom query queues.

    // tokens - is an array of all the tokens in the provided text.
    // lastToken - is the last of these tokens
    // firstToken - is the first of these tokens

    // This queues up the queries that will be made at
    // timed intervals until we see at least minimumItems
    // in the items bucket.

    // Set-up some useful tokens.

    const strippedTokens = stripStopwords(tokens.join(' ')).split(' ');

    const firstToken = strippedTokens[0];
    var lastToken = strippedTokens[tokens.length - 1];

    const mixedTokens = extractMixedTokens(tokens);
    const firstMixedToken = mixedTokens && mixedTokens.length > 0 ? mixedTokens[0] : false;

    // Announce the start
    console.log("GimmuItemList initQueries start");
    // First 3 tokens of string.


    // If there are more than four tokens, then try a broad capture.
//console.log("ebay text tokens", tokens)
    if (strippedTokens.length > 4) {
      //console.log("ebay text more than four tokens")
      var textProcessedTokens = bestFourTokens(strippedTokens.join(" "));
      addQuery(textProcessedTokens);
    } else {
      addQuery(strippedTokens.join(' '));
    }


    // First 2 tokens of string.
    addQuery(strippedTokens.slice(0, 2).join(" "));

    // The first token of the string.
    addQuery(firstToken);

    // The queue does not allow duplicates.
    // But to available that race.

    if (firstToken !== lastToken) {
      addQuery(lastToken);
    }

    // Do first and last token,
    // if there is more than one token.

    if (strippedTokens.length > 1) {
      addQuery((firstToken + " " + lastToken).trim());
    }

    addQuery(strippedTokens[1]);
    addQuery(strippedTokens[2]);
    addQuery(strippedTokens[3]);
    addQuery(strippedTokens[4]);
    addQuery(strippedTokens[5]);
    addQuery(strippedTokens[6]);
    addQuery(strippedTokens[7]);
  }

  /*
Watchers for console logging dev
*/
  /*
  useEffect(() => {
    if (keywords === undefined) {
      return;
    }
    setTokens(keywords.split(" "));
    const itemId = Gimmu.extractItemId(keywords);

    if (item && itemId !== item.id) {
      setItem({ ...item, id: itemId });
    }

    setStatus({
      ...status,
      itemsState: "searching",
      message: "New keywords seen.",
    });
  }, [keywords]);
*/
  useEffect(() => {
    //console.log("GimmuItemList xkcd items", queryStack);
    if (items === undefined) {
      console.log("GimmuItemList items undefined");
      //     setStatus({ status: "waiting", message: "Undefined items. Roh. Roh." });
      //getNextQuery();
      return;
    }

    if (items === null) {
      console.log("GimmuItemList items null");
      //     setStatus({ message: "Undefined items. Roh. Roh." });
      //getNextQuery();

      return;
    }

    if (items.length === 0) {
      console.log("GimmuItemList items items.length");
      //getNextQuery();
      //setStatus("No items found.");
      return;
    }

    
    if (
      processedItems.length > 0 &&
      processedItems.length < itemsMinimum &&
      queryStack.length === 0 &&
      queryHistoryStack.length > 0  
    ) {
      console.log("GimmuItemList itemsMinimum", items[0].title);
      const c = bestFourTokens(items[0].title);
      addQuery(c);
      return;
    }
    

    var match = false;
    // If we saw one itemId use that to get the item.
    items.some((i) => {
      if (item && item.id && i.id === item.id) {
        setItem({ ...item, i });
        var match = true;
        setStatus({
          ...status,
          itemState: "waiting",
          message: "Saw the id in the set.",
        });

        return true;
      }
    });

    // All else fails use first item returned.
    if (match === false) {
      setStatus({
        ...status,
        itemState: "waiting",
        //  itemsState: "waiting",
        message: "No direct match.",
      });
      console.log("setItem", items[0]);
      setItem(items[0]);
    }
  }, [items, itemsStatus, processedItems]);

  useEffect(() => {
    //console.log("itemsMinimum", itemsMinimum, items.length);
    if (processedItems.length > itemsMinimum) {
      console.log("GimmuItemList interval More than minimum items");

      return;
    }

    if (queryStack.length === 0) {
      return;
    }
    //getNextQuery();
    console.log("itemsMinimum", itemsMinimum, processedItems.length);

    const interval = setInterval(() => {
      console.log("GimmuItemList interval queryStack", queryStack);
      if (processedItems.length > itemsMinimum) {
        console.log("GimmuItemList interval More than minimum items");
        clearInterval(interval);
        return;
      }
      /*
if (failCount === 0) {

      setStatus({
        ...status,
        itemState: "waiting",
        itemsState: "waiting",
        message: "Failcount.",
      });

        clearInterval(interval);
        return;


}
*/
      if (queryStack.length === 0) {
        console.log("GimmuItemList interval queryStack empty");

        setFailCount(failCount - 1);

        return;
      }
      console.log("GimmuItemlist");

      getNextQuery();

      // Slow down so we can see what is happening
      //    }, searchInterval);
    }, searchInterval);

    return () => clearInterval(interval);
  }, [queryStack, items, processedItems, failCount]);

  useEffect(() => {
    //console.log(item.itemId);
    if (items == null) {
      return;
    }

    if (itemsStatus !== "success") {
      return;
    }

    // Drop items with variants.
    //const processedItemsx = items;

    const itemsPreScored = Gimmu.scoreItems(items, keywords);

    const processedItemsz = itemsPreScored.filter(
      (item) => item.variants === false
    );

    // This should
    //    const processedItemsy =processedItemsz.filter((item) => (item.categoryPath.includes('train') || item.notGimmuScore > 1));

    //    const processedItemsy = processedItemsz.filter((item) => (true));
    var processedItemsy = processedItemsz;
console.log("minimumScore", minimumScore);
    if (true) {
      processedItemsy = processedItemsz.filter((item) => {
        //console.log("item",item);
        //console.log("item.siteScore", item.siteScore);
        //return true;
//        if (item.siteScore === undefined) {
        if (item.score === undefined) {

          return false;
        }
//        return item.siteScore >= minimumScore;
        return item.score >= minimumScore;


      });
    }
    //const processedItemsy = processedItemsz;

    // We only have title and categoryPath

    // Drop items which don't mention train or railroad

    //"Toys & Hobbies|Model Railroads & Trains|Railroads & Trains|Other Railroads & Trains";

    var processedItemsj = processedItemsy;

    if (!skipDefaultKeywordFilter) {
      processedItemsj = processedItemsy.filter((item) =>
        item.title.toLowerCase().includes(defaultKeyword)
      );
    }

    // Use this pattern to filtern out matching phrases. Cascade.
    // const processedItemsh = processedItemsj.filter((item) => (!item.title.toLowerCase().includes("lionel ritchie")))
    // const processedItemsi = processedItemsh.filter((item) => (!item.title.toLowerCase().includes("messi")))
    // const processedItemsx = processedItemsi.filter((item) => (!item.title.toLowerCase().includes("jeffries")))
    var processedItemsx = processedItemsj;
    if (!skipNotKeywordFilter) {
      var processedItemsx = processedItemsj.filter((item) => {
        const tokens = getSlug(item.title).split("-");
        // console.log("GimmuItemList tokens", tokens);
        //return false;

        // some - true if any one item matches the condition.
        // in this case does anything in this title matches
        const hasThing = defaultNotKeywords.some((notKeyword) => {
          // console.log("item.title", item.title);
          //return true;
          return item.title.toLowerCase().includes(notKeyword);
        });
        // console.log("hasThing", hasThing);

        return !hasThing;
      });
    }

    //var processedItemsx = processedItemsj;
    // https://stackoverflow.com/questions/23921683/javascript-move-an-item-of-an-array-to-the-front/48456512
    if (processedItemsx === undefined) {
      return;
    }

    if (
      processedItems.length === 0 &&
      processedItemsx.length === 0 &&
      itemsStatus === "success"
    ) {
      //window.location.href = "/notfound";
    }

    const i = [...processedItems, ...processedItemsx];

    const j = Gimmu.uniqueItems(i);

    // rescore items because we may have stale items from prior searches
    const scoredItems = Gimmu.scoreItems(j, keywords);

    var minScore = null;
    if (tokens.length > 1) {
      minScore = 0;
    }

    var filteredItems = filterItemsByScore(scoredItems, minScore);

    const sortedItems = sortItemsByScore(filteredItems);

    if (sortedItems.length > itemsMinimum) {
      setStatus({
        ...status,
        itemsState: "waiting",
        message: "Found " + sortedItems.length + " items like that.",
      });
    }

    if (queryStack.length === 0) {
      setStatus({
        ...status,
        itemsState: "waiting",
        message: "Query stack empty.",
      });
    }

    setProcessedItems(sortedItems);
  }, [items, itemsStatus]);

  useEffect(() => {
    if (processedItems === undefined) {
      return;
    }
    if (processedItems.length < itemsMinimum) {
      setStatus({
        ...status,
        itemsState: "searching",
        message: "Found " + processedItems.length + " items like that.",
      });
    }

    if (processedItems.length > itemsMinimum) {
      setStatus({
        ...status,
        itemsState: "waiting",
        message: "Found " + processedItems.length + " items like that.",
      });
    }
  }, [processedItems, setProcessedItems]);

  useEffect(() => {
    var match = false;
    // If we saw one itemId use that to get the item.
    processedItems.some((i) => {
      if (item && i.id === item.id) {
        setStatus({ ...status, itemState: "waiting" });
        setItem({ ...item, i });

        var match = true;
        //setStatus('Found the exact thing.');
        return true;
      }
    });

    // All else fails use first item returned.
    if (match === false) {
      if (item && item.id) {
      } else {
        setStatus({ ...status, itemState: "waiting" });
        setItem(processedItems[0]);
      }
    }

    //if (item && item.id) {
    // ID is set. Use set item.
    //setProcessedItems(item);
    //return;
    //}

    // Id is not set. Use best item from processedItem set
    //setProcessedItem(processedItems[0]);
    //setProcessedItem(item);

    console.log("Processed item", processedItem);
  }, [processedItems]);

  /*
Hook to call eBay and get a wide spectrum response.
*/

  const SearchReport = (props) => {
    if (status === undefined) {
      return null;
    }
    if (status === null) {
      return null;
    }
    console.log("SearchReport status", status);
    return (
      <>
        {status.itemState === "searching" ||
        status.itemsState === "searching" ? (
          <LinearProgress
            color="secondary"
            style={{ height: "10px", borderRadius: "15px" }}
          />
        ) : (
          "Empty pad space"
        )}

        <div>ITEM STATE {status.itemState}</div>
        <div>ITEMS STATE {status.itemsState}</div>
        <div>MESSAGE {status.message}</div>
        <div>
          UNUSED QUERIES AVAILABLE {queryStack.length}
          <Stack stack={queryStack} />
        </div>
        <div>
          QUERIES PERFORMED {queryHistoryStack.length}
          <Stack stack={queryHistoryStack} />
        </div>
        {processedItems && processedItems.length > 0 ? (
          <>Found {processedItems.length} items like that.</>
        ) : (
          "---"
        )}
      </>
    );
  };

  const ItemsCounter = (props) => {
    const { items, item } = props;
    if (items === undefined || items === null) {
      return <>SKELINGTN</>;
    }

    // See if there is an item for display.
    if (item && item.id && itemPanelFlag) {
      return <>{items.length - 1}</>;
    }

    return <>{items.length}</>;
  };

  const itemsAreEmpty = !processedItems || processedItems.length === 0;

if (status.itemsState === 'searching') {
//return <CircularProgress />;
// return (
// <SearchHeader tokens={tokens} keywords={keywords} items={processedItems} />
//         )   
var message = "Looking"
if (processedItems.length === 0) {
message = "Loading the best deals...";
}
if (processedItems.length === 1) {
  message = "Loading... Found 1 deal";
  }
  if (processedItems.length > 1) {
    message = "Loading... found " + processedItems.length + " deals";
    }


return <>


<ListSkeleton listsToRender={12} />

{/* <Box
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",

          opacity: 1,
    
          width: "100%",
      
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box display="block" textAlign="center">

          <img style={{height:"150px", width:'auto'}} src={loadingicon} />

          <Box my={3}>
            <Typography variant="h4">
            {message}
            </Typography>
          </Box>
        </Box>
      </Box> */}


</>

}

  return (
    <>
      {/* {eventId && processedItem && processedItem.id ? (
             <Meta title={processedItem.title} description={REACT_APP_DESCRIPTION} /> 
             ) : null} */}

      <Container maxWidth="xl">
        {itemsError && (
          <Box mb={3}>
            <Alert severity="error">{itemsError.message}</Alert>
          </Box>
        )}
        {true && (
          <>
            <Box>{searchReportFlag && <SearchReport />}</Box>
            {itemPanelFlag && item && item.id ? (
              <ItemPanel key={item.id} item={item} />
            ) : null}

            <SearchHeader tokens={tokens} keywords={keywords} items={processedItems} />
            {showPriceMetrics && <PriceMetrics items={processedItems} />}
            {/* <Box pb={2}></Box> */}
            {showKeywords && 
            <Keywords keywords={keywords} items={items} />}
            {/* <Box my={2} pb={2}>
              <Divider />
            </Box> */}
            {/* <Box py={1}>
              <Typography>
                <b>
                  Related Items & History:{" "}
                  <ItemsCounter item={processedItem} items={processedItems} />
                </b>
              </Typography>
            </Box> */}

            <ItemsGrid items={processedItems} item={item} flavour={flavour} />
          </>
        )}
      </Container>
      {/* </Section> */}
    </>
  );
}

export default GimmuItemList;
