import React from "react";
import Section from "./Section";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Link } from "./../util/router.js";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
//import List from "@material-ui/core/List";
//import ListItem from "@material-ui/core/ListItem";
//import ListItemText from "@material-ui/core/ListItemText";
//import ListItemIcon from "@material-ui/core/ListItemIcon";
import useDarkMode from "use-dark-mode";
import { makeStyles } from "@material-ui/core/styles";

//import SectionHeader from "./SectionHeader";

const { REACT_APP_LOGO, REACT_APP_SEARCH_BAR, REACT_APP_SITE_TITLE } =
  process.env;
//import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  sticky: {
    marginTop: "auto",
  },
  brand: {
    display: "block",
    height: 32,
  },

  listItem: {
    paddingTop: 2,
    paddingBottom: 2,
    // paddingLeft: 12,
    paddingRight: 12,
    color: "#ffffff",
  },

  listItemTextHeader: {
    fontWeight: "bold",
    color: "#ffffff",
  },
  socialIcon: {
    minWidth: 30,
  },
  white: {
    color: "#ffffff",
  },
}));

function Footer(props) {
  const classes = useStyles();

  const darkMode = useDarkMode();
  // Use inverted logo if specified
  // and we are in dark mode

  //const logo =
  //  props.logoInverted && darkMode.value ? props.logoInverted : props.logo;

  return (
    <Section
      // bgColor={props.bgColor}
      style={{ backgroundColor: "#f8f8f8" }}
      size={props.size}
      // bgImage={props.bgImage}
      // bgImage="https://cdn.pixabay.com/photo/2021/05/05/11/43/background-6230888_960_720.png"
      bgImageOpacity={props.bgImageOpacity}
      className={props.sticky && classes.sticky}
    >
      <Container style={{ textAlign: "center" }}>
        {/* {props.description && (
           
                <Typography component="p" className={classes.white}>
                  {REACT_APP_SEARCH_BAR}
                </Typography>
          
            )} */}
        <Box py={1}>
          <Grid container={true} style={{ minHeight: "50px" }}>
            {/* <Link to="/">
              
              <img src={logo} alt="Logo" className={classes.brand} />
            </Link> */}

            {/* <Link to="/" style={{ textDecoration: "none" }}>
           
              <Typography
                style={{
                  color: "#ffffff",
                  fontSize: "3em",
                  fontWeight: "600",
                  letterSpacing: "-2px",
                }}
              >
                {REACT_APP_SITE_TITLE}
              </Typography>
            </Link> */}

            <Grid item xs={12}>
              <Typography
                component="p"
                color="textSecondary"
                variant="subtitle2"
              >
                <div style={{ display: "inline-block" }}>
                  A Gimmu Company{" "}
                  <span style={{ fontSize: "2em", verticalAlign: "middle" }}>
                    🇺🇸
                  </span>
                </div>
              </Typography>
            </Grid>

            <Grid item xs={4} sm={4}>
                <Link
                  to="/termsandconditions"
                  style={{ textDecoration: "none" }}
                >
                  {/* <img src={logo} alt="Logo" className={classes.logo} /> */}
                  <Box mt={1}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Terms & Conditions
                    </Typography>
                  </Box>
                </Link>
              </Grid>
              
              <Grid item xs={4} sm={4}>
              {props.copyright && (
                <Box mt={1}>
                  <Typography
                    variant="body2"
                    component="p"
                    color="textSecondary"
                  >
                    {props.copyright}
                  </Typography>
                </Box>
              )}
            </Grid>


            <Grid item xs={4} sm={4}>
                <Link to="/privacy" style={{ textDecoration: "none" }}>
                  {/* <img src={logo} alt="Logo" className={classes.logo} /> */}
                  <Box mt={1}>
                    <Typography
                      component="p"
                      variant="body2"
                      color="textSecondary"
                    >
                      Privacy Policy
                    </Typography>
                  </Box>
                </Link>
              </Grid>


           
              
          </Grid>
        </Box>
      </Container>
    </Section>
  );
}

export default Footer;

// <Grid item={true} xs={12} md={4}>
// <List disablePadding={true} disableGutters>
//   <ListItem disableGutters className={classes.listItem}>
//     <Typography
//       variant="overline"
//       className={classes.listItemTextHeader}
//     >
//       Product
//     </Typography>
//   </ListItem>

//   <ListItem
//     button={true}
//     className={classes.listItem}
//     component={Link}
//     to="/pricing"
//     disableGutters
//   >
//     <ListItemText
//       className={classes.listItem}
//       disableTypography
//       primary={
//         <Typography type="body1" style={{ color: "#FFFFFF" }}>
//           Pricing
//         </Typography>
//       }
//     />
//   </ListItem>

//   <ListItem
//     button={true}
//     className={classes.listItem}
//     component={Link}
//     to="/faq"
//     disableGutters
//   >
//     <ListItemText
//       className={classes.listItem}
//       disableTypography
//       primary={
//         <Typography type="body1" style={{ color: "#FFFFFF" }}>
//           FAQ
//         </Typography>
//       }
//     />
//   </ListItem>
// </List>
// </Grid>
