import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getSlug } from "./../util/slug.js";
import { formatPrice } from "./../util/text.js";
import ItemThumbnail from "./../components/ItemThumbnail";
import DirectBuy from "./../components/DirectBuy";
import CornerRibbon from "react-corner-ribbon";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Divider,
  CircularProgress,
  Grid
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import {ebayURL} from "./../util/ebay.js";
import ReactGA from "react-ga4";

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: "15px",
    // marginTop: "15px",
    borderColor: "transparent",
    "&:hover": {
      borderColor: "#0000001f",
      border: "1px solid",
    },
  },
  "&:hover": {
    borderColor: "red",
  },

  estimate: {
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
  },

  media: {
    height: "200px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },

  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  name: {
    overflow: "hidden",
    display: "-webkit-box",
    // minHeight: "50px",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    lineHeight:1.2,
    minHeight:'2em',
    [theme.breakpoints.up('sm')]: {
      WebkitLineClamp: 2,
    },
  },
  CardActions: {
    justifyContent: "center",
  },
  ribbon: {
    backgroundColor: 'skyblue',
    position: "absolute",
    color: 'white',
    width: 150,
    zIndex: 3,
    textAlign: 'center',
    textTransform: 'uppercase',
    padding: 5,
    font: 'Lato',
    '&::before': {
        position: "absolute",
        zIndex: -1,
        content: '',
        display: 'block',
        border: '5px solid #2980b9',
    },
    '&::after': {
        position: "absolute",
        zIndex: -1,
        content: '',
        display: 'block',
        border: '5px solid #2980b9',
    },
    transform: 'rotate(-45deg)',
    top: 60,
    marginLeft: -40,
},
}));

//const envShowItemScore = parseInt(process.env.REACT_APP_SHOW_ITEM_SCORE || "");
//const showItemScore = Number.isInteger(envShowItemScore)
const showItemScore = process.env.REACT_APP_SHOW_ITEM_SCORE.toLowerCase().includes("true");

const webPrefix = process.env.REACT_APP_WEB_PREFIX;
const internalThumbnailURL = process.env.REACT_APP_INTERNAL_THUMBNAIL_URL.toLowerCase() === 'true'; // skips

const includeItemIdURL = true;

function ItemCard(props) {
  const classes = useStyles();

  const [thumbStatus, setThumbStatus] = useState("loading");

  var { item, flavour } = props;

  const itemIsEmpty = !item;
  const [itemStatus, setItemStatus] = useState("loading");

  var thumbnailDestinationURL = "/";
  var sellerItemURL = "/";

//  if (internalThumbnailURL) {
  thumbnailDestinationURL = "/" + getSlug(item.title);

  if (includeItemIdURL) {
    thumbnailDestinationURL = thumbnailDestinationURL + "-" + item.itemId;
  }

    if (!internalThumbnailURL) { // false or at least not true.
      const u = ebayURL(item);
      sellerItemURL = u;
      thumbnailDestinationURL = u;
    }


    function handleThumbnailClick() {
      // Send a custom event
  ReactGA.event({
  category: "click",
  action: "eBay Thumbnail click",
  // label: "your label", // optional
  // value: 99, // optional, must be a number
  // nonInteraction: true, // optional, true/false
  // transport: "xhr", // optional, beacon/xhr/image
  });
}
  function handleItemTitleClick() {
    // Send a custom event
ReactGA.event({
category: "click",
action: "eBay Item Card Title click",

});
  }

  function handleItemPriceClick() {
    // Send a custom event
ReactGA.event({
category: "click",
action: "eBay Item Card Price click",

});
  }
  
  


  useEffect(() => {
    if (item === undefined) {
      return;
    }

    setItemStatus("success");
  }, [item]);

  if (item === undefined) {
    return <>UNDEFINED</>;
  }
  if (item === null) {
    return <>NULL</>;
  }
  if (itemStatus === "loading") {
    return (
      <>
       
        <Skeleton variant="rect" width="100%" height={200} />
      </>
    );
  }
  if (!(item && item.title)) {
    return <>NO TITLE</>;
  }

  if (itemStatus === "loading" || itemIsEmpty) {
    return (
      <Box py={5} px={3} align="center">
        {itemStatus === "loading" && <CircularProgress size={32} />}

        {itemStatus !== "loading" && itemIsEmpty && (
          <>Nothing yet. Click the button to add your first item.</>
        )}
      </Box>
    );
  }

  return (
    <>

  <div style={{ position: "relative" }}>

    
      <Card
        elevation={0}
        className={classes.root}
        key={item.itemId}
        variant="outlined"
        style={{
          textDecoration: "none",
          height: "100%",
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >

        
        <Box flexGrow={1} >

<Grid container>
<Grid item xs={5} sm={12} style={{background:'#f5f5f5'}}>


    {/* {item.shippingInfo[0].shippingType === "Free" && (<> */}
   {/* {item.score}
    {item.score <= 6 && (<>
<CornerRibbon
          position="top-left" // OPTIONAL, default as "top-right"
          fontColor="#f0f0f0" // OPTIONAL, default as "#f0f0f0"
          backgroundColor="#2c7" // OPTIONAL, default as "#2c7"
          containerStyle={{}} // OPTIONAL, style of the ribbon
          style={{}} // OPTIONAL, style of ribbon content
          className="" // OPTIONAL, css class of ribbon
          >
    CLOSE MATCH

        </CornerRibbon>
        </>) } */}
        {/* </>)} */}

        <a href={thumbnailDestinationURL} onClick={()=>handleThumbnailClick()}  target="_blank">
          <CardMedia
            className={classes.media}
            //component={Link}
            //to={thumbnailDestinationURL}
          >
           
            <ItemThumbnail item={item} />
     
          </CardMedia>
</a>
          </Grid>
          {/* <Divider light={true} /> */}


          <Grid item xs={7} sm={12}>
          <CardContent
            style={{ paddingBottom: "0px", textDecoration: "none" }}
          >
          {/* <Box px={1} style={{background:'orange'}}><Typography variant='subtitle1'>Hot Deal</Typography></Box> */}
          <a href={thumbnailDestinationURL} onClick={()=>handleItemTitleClick()}  target="_blank">

            <Typography
              variant="body1"
              color="primary"
              gutterBottom
              className={classes.name}
              style={
                {
                  // fontWeight: 500,
                  // fontFamily: '"Cormorant Garamond", "serif"',
                }
              }
    
            >
              {item.title}
{showItemScore && (<>{' '}{item.score}</>)}
            </Typography>

      </a>
           
            <Typography
              variant="h6"
              color="primary"
              style={{
                fontWeight: 600,
              }}
            >


<a href={thumbnailDestinationURL} style={{
                  textDecoration: "none",
                  color: "black",
                  fontWeight: 700,
                }} onClick={()=>handleItemTitleClick()}  target="_blank">

      
                $
                {formatPrice(
                  item.sellingStatus[0].convertedCurrentPrice[0].__value__
                )}
              </a>
            </Typography>

            </CardContent>
   

          <CardActions className={classes.CardActions}>

{(flavour === 'details' || flavour === 'standard') && (
            <Button
              variant="outlined"
              disableElevation
              fullWidth
              component={Link}
              to={thumbnailDestinationURL}
            >
              View Details
            </Button>)}

{flavour === 'direct-buy' && (

<><DirectBuy item={item} flavour={'direct-buy'}  /></>

)}


          </CardActions>




          </Grid>
          
          </Grid> 
        </Box>
        
      </Card>

    </div>
    </>
  );
}

export default ItemCard;
