import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { useWatch } from "react-hook-form";
import { formatPrice } from "./../util/text.js";
import { Button, Typography, Grid, Fade } from "@material-ui/core";
import ImageCarousel from "./../components/ImageCarousel";
import Skeleton from "@material-ui/lab/Skeleton";
import EbayItemButton from "./../components/EbayItemButton";
import AmazonItemButton from "./../components/AmazonItemButton";
import { useItemByItemId } from "./../util/ebay";
//import amazonbuy from "./../images/amazonbuy.png";
import LinearProgress from "@material-ui/core/LinearProgress";

const { REACT_APP_SITE_TITLE, REACT_APP_KEYWORD } = process.env;

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },

  name: {
    overflow: "hidden",
    display: "-webkit-box",
    // minHeight: "50px",
    WebkitLineClamp: 10,
    WebkitBoxOrient: "vertical",
  },
  colorPrimary: {
    backgroundColor: "#e91e1e",
    height: 20,
    borderRadius: 5,
  },
  barColorPrimary: {
    backgroundColor: "#4caf50",
    height: 20,
    borderRadius: 5,
  },
}));

function DirectBuy(props) {
  const { filter } = props;
  var { flavour } = props;

  var { item } = props;

  /*
  const {
    data: fullItem,
    status: itemStatus,
    error: itemError,
  } = useItemByItemId(item.id);
*/

  const [currentItem, setCurrentItem] = useState(item);

  // This gets run when item changes.



  useEffect(() => {
    console.log("DirectBuy item" , item);
  }, [item]);

  var { flavour } = props;

  const classes = useStyles();

  const keywords = "phone";
/*
  if (fullItem === null) {
    return null;
  }

  if (itemStatus === "loading") {
    return (
      <>
        <Fade in={fullItem} timeout={1000}>
          <Skeleton
            variant="rect"
            width="100%"
            height={350}
            style={{ marginBottom: "3em" }}
          />
        </Fade>
      </>
    );
  }
  */
/*
  if (itemStatus !== "success") {
    return "NOT SUCCESS";
  }

  if (itemError !== null) {
    return <>COULD NOT GET ITEM DETAILS</>;
  }

  if (fullItem === undefined) {
    return <>LOADING ITEM DETAILS</>;
  }

  if (!fullItem.title.toLowerCase().includes(REACT_APP_KEYWORD)) {
    return "ITEM NOT LIONEL";
  }
*/
  return (
    <>
      <Grid container spacing={1}>
  

        <Grid item xs={12} sm={6}>
          <AmazonItemButton item={item} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <EbayItemButton item={item} />
        </Grid>

      </Grid>

    </>
  );
}

export default DirectBuy;
