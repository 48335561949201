import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import { Button, Avatar, Box, IconButton, useMediaQuery } from "@material-ui/core";

import { getPositiveSlug } from "./../util/slug.js";
import SearchIcon from '@material-ui/icons/Search';
import ReactGA from "react-ga4";
import atamazon from "./../images/atamazon.png";

const useStyles = makeStyles({
  buttonStep: {
    width: "50px",
  },
});

function AmazonItemButton(props) {
  const classes = useStyles();
  const [itemStatus, setItemStatus] = useState("loading");
  //  const [itemError, setItemError] = useState(null);
  var { item } = props;
  const [URL, setURL] = useState();

  useEffect(() => {
    const amazonSearchTrackingId = "gimmu-20";
    console.log("AmazonItemButton item", item)
var title = "";
    if (item.Title) {
      title = item.Title;
    }

    if (item.title) {
      title = item.title;

    }

    const slug = getPositiveSlug(title);

    const amazonURL =
      "https://www.amazon.com/gp/search?ie=UTF8&tag=" +
      amazonSearchTrackingId +
      "&keywords=" +
      slug;

    setURL(amazonURL);
    setItemStatus("success");
  }, [item]);

    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("xs"));
  
    const buttonProps = {
      variant: isSmallScreen ? "outlined" : "outlined",
      size: isSmallScreen ? "small" : "large"
    };
  
    function handleClick() {
      // Send a custom event
ReactGA.event({
  category: "click",
  action: "amazon Button click",
  // label: "your label", // optional
  // value: 99, // optional, must be a number
  // nonInteraction: true, // optional, true/false
  // transport: "xhr", // optional, beacon/xhr/image
});
console.log("AmazonItemButton handleClick")


// analytics.track('amazonItemButton', {
//   item: item.title,
// })

    }

  if (itemStatus === "loading") {
    return null;
  }

  return (
    <>
     <a href={URL} onClick={()=>handleClick()} target="_blank">
     
        <Button
     
        startIcon={<SearchIcon />}
          fullWidth
          variant="outlined"
          disableElevation
          // size="large"
          {...buttonProps}
          style={{ fontWeight: "700" }}
        >

          <Box style={{ display: "flex" }}>
            <img src={atamazon} alt="button" width="auto" height="30" />

          </Box>
        </Button>

     </a> 
    </>
  );
}

export default AmazonItemButton;
