import React from "react";

function NotFoundPage(props) {
  return (
    <div
      style={{
        padding: "50px",
        width: "100%",
        textAlign: "center",
      }}
    >
      The page could not be found
    </div>
  );
}

export default NotFoundPage;
