import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import ItemCard from "./../components/ItemCard";
import { Box, Typography } from "@material-ui/core";
import LazyLoad from 'react-lazyload';
import { scoreItem } from "../util/gimmu";

const {
  REACT_APP_SHOW_ITEM_PANEL,
} = process.env;

const itemPanelFlag = REACT_APP_SHOW_ITEM_PANEL.toLowerCase() === 'true';

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
}));

function ItemsGrid(props) {
  const { filter, items, item } = props;

  var { flavour } = props;

  const classes = useStyles();

  const itemsAreEmpty = !items || items.length === 0;

  useEffect(() => {
    //console.log("ItemsGrid items",items);
  }, [items]);

  //return (<>MERP</>);

  if (itemsAreEmpty) {
    return null;
  }

  if (items === null) {
    return;
  }

  if (items === undefined) {
    return <>UNDEFINED</>;
  }
  if (items.length === 0) {
    return <>EMPTY SET</>;
  }
  return (
    <>
      <Box py={1}>


        <Grid container={true} spacing={1}>
          {items && items
            .filter((_item) => !itemPanelFlag || (item && _item.id !== item.id))
            .map((_item, index) => (
              <Grid
                key={_item.itemId}
                item={true}
                xl={2}
                lg={3}
                md={4}
                sm={6}
                xs={12}
                //key={index}
              >
                <Box style={{ border: "1px solid #e0e0e0" }}>
                <LazyLoad  offset={100}>
                 
                  <ItemCard key={index} item={_item} flavour={flavour} />
                  </LazyLoad>
                </Box>
              </Grid>
            ))}
        </Grid>
      </Box>
    </>
  );
}

export default ItemsGrid;
