import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { useWatch } from "react-hook-form";
import { formatPrice } from "./../util/text.js";
import { Button, Typography, Grid, Fade } from "@material-ui/core";
import ImageCarousel from "./../components/ImageCarousel";
import Skeleton from "@material-ui/lab/Skeleton";
import EbayItemButton from "./../components/EbayItemButton";
import AmazonItemButton from "./../components/AmazonItemButton";
import { useItemByItemId } from "./../util/ebay";
//import amazonbuy from "./../images/amazonbuy.png";
import LinearProgress from "@material-ui/core/LinearProgress";
import { humanMonthYearTime } from "./../util/time";
import { capitalizeEachWord, textCapitalize } from "./../util/text";
import { extractItemId } from "./../util/gimmu";
import { getSlug } from "./../util/slug.js";

const { REACT_APP_SITE_TITLE, REACT_APP_KEYWORD } = process.env;

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },

  name: {
    overflow: "hidden",
    display: "-webkit-box",
    // minHeight: "50px",
    WebkitLineClamp: 10,
    WebkitBoxOrient: "vertical",
  },
  colorPrimary: {
    backgroundColor: "#e91e1e",
    height: 20,
    borderRadius: 5,
  },
  barColorPrimary: {
    backgroundColor: "#4caf50",
    height: 20,
    borderRadius: 5,
  },
}));

function SearchHeader(props) {
  const { filter, tokens, items, keywords } = props;
  var { flavour } = props;

  var { item } = props;
  const [searchQuery, setSearchQuery] = useState();
  const keywordsQuery =
    keywords === "" || keywords === undefined ? "something" : keywords;

  const countItems = items ? items.length : 0;

  const classes = useStyles();
  const textDeals = countItems === 1 ? "Deal" : "Deals";

  var isReady = countItems >= 1;

  useEffect(() => {
    var lastToken = tokens[tokens.length - 1];

    var newTokens = tokens;
    if (extractItemId(lastToken) !== false) {
      newTokens.pop();
    }

    const keywordsQuery =
      newTokens === [] || newTokens === undefined
        ? "something"
        : newTokens.join(" ");

    const capitalizedWordsQuery = keywordsQuery.replace(
      /(^\w{1})|(\s+\w{1})/g,
      (letter) => letter.toUpperCase()
    );

    const textCapitalizeQuery = textCapitalize(keywordsQuery, items.map((item)=>{return item.title}));

// Try and use the formatted text from an item 
// for the search header text
var t = capitalizedWordsQuery;
items.map((item)=>{
if (getSlug(item.title) === getSlug(capitalizedWordsQuery)) {
t = item.title;

}

});

 //   setSearchQuery(t);

    const s = textCapitalize(keywordsQuery, items.map((item)=>{return item.title}));
setSearchQuery(s);

  }, [tokens, items]);
  // Do some maths. When the items change.

  if (!isReady) {
    return null;
  }

  return (
    <Typography variant="h4">
      {countItems} {textDeals} on {searchQuery} (
      {humanMonthYearTime()})
    </Typography>
  );
}

export default SearchHeader;
