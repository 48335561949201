import React from "react";
import HeroSection from "./../components/HeroSection";
// import StatsSection from "./../components/StatsSection";
// import TeamBiosSection from "./../components/TeamBiosSection";
// import CtaSection from "./../components/CtaSection";

function TermsPage(props) {
  return (
    <>

   

    <HeroSection
      
      />
    </>
  );
}

export default TermsPage;
