import React, {useEffect, useState} from "react";
import Box from "@material-ui/core/Box";
import GimmuItemList from "./../components/GimmuItemList";
import Meta from "../components/Meta";
import Search from "../components/Search";
import { Container } from "@material-ui/core";

const { REACT_APP_KEYWORDS } = process.env;
  
function IndexPage(props) {
  const _event={id:'REACT_APP_KEYWORDS'};
  const [item, setItem] = useState([]);

  return (
    <>
    {/* <Meta /> */}


<Container>

    <Box
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -90%)",
          // backgroundColor: "#ffffff",
          opacity: 1,
          // zIndex: 9999,
          // height: "100%",
          width: "100%",
          maxWidth:'960px',
          // display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
         <Search  />
      </Box>
      </Container>   
            {/* <GimmuItemList event={_event} flavour={"standard"} /> */}
   
         
    </>
  );
}

export default IndexPage;
