import React from "react";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core/styles";
import * as colors from "@material-ui/core/colors";
import CssBaseline from "@material-ui/core/CssBaseline";
import useDarkMode from "use-dark-mode";

const themeConfig = {
  // Light theme
  light: {
    palette: {
      type: "light",
      primary: {
        // Use hue from colors or hex
        // main: '#3c4043',
        main: "#212223",
        success: "#4caf50",
        // Uncomment to specify light/dark
        // shades instead of automatically
        // calculating from above value.
        //light: "#4791db",
        //dark: "#115293",
      },
      secondary: {
        // main: colors.'pink["500"]',

        main: "#f44336",
      },
      background: {
        // Background for <body>
        // and <Section color="default">
        default: "#fff",
        // Background for elevated
        // components (<Card>, etc)
        paper: "#fff",
        // Background for footer
        footer: "#3d4853",
      },
    },
  },

  // Dark theme
  dark: {
    palette: {
      type: "dark",
      primary: {
        // Same as in light but we could
        // adjust color hue if needed
        main: colors.indigo["500"],
      },
      secondary: {
        main: colors.pink["500"],
      },
      background: {
        default: colors.grey["900"],
        paper: colors.grey["800"],
      },
    },
  },

  // Values for both themes
  common: {
    typography: {
      fontSize: 12.2,
      // fontFamily: '"Montserrat", "sans-serif"',
      fontFamily: '"Roboto", "sans-serif"',
      textTransform: "none",
      // Uncomment to make button lowercase
      // button: { textTransform: "none" },
      allVariants: {
        color: "#000",
      },
    },

    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 790,
        // md: 1200,
        lg: 1060,
        xl: 1650,
      },
    },
    // Override component styles
    overrides: {
      MuiDialogContainer: {
        root: {
          PaperProps: {
            padding: "100px",
          },
        },
      },

      // MuiContainer: {
      //   root: {
      //     maxWidth:'5080px',
      //   }
      // },

      MuiMenuItem: {
        root: {
          display: "flex",
          justifyContent: "space-between",
        },
      },

      MuiInputLabel: {
        // Name of the component ⚛️ / style sheet
        root: {
          // Name of the rule
          fontWeight: 500,
          "&$focused": {
            // increase the specificity for the pseudo class
            fontWeight: 600,
          },
        },
      },

      // Global styles
      MuiCssBaseline: {
        "@global": {
          "#root": {
            // Flex column that is height
            // of viewport so that footer
            // can push self to bottom by
            // with auto margin-top
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            // Prevent child elements from
            // shrinking when content
            // is taller than the screen
            // (quirk of flex parent)
            "& > *": {
              flexShrink: 0,
            },
          },
        },
      },
    },
  },
};

function getTheme(name) {
  // Create MUI theme from themeConfig
  return createTheme({
    ...themeConfig[name],
    // Merge in common values
    ...themeConfig.common,
    overrides: {
      // Merge overrides
      ...(themeConfig[name] && themeConfig[name].overrides),
      ...(themeConfig.common && themeConfig.common.overrides),
    },
  });
}

export const ThemeProvider = (props) => {
  // Detect dark mode based on stored value
  // with fallback to system setting
  // const darkMode = useDarkMode();
  // Get MUI theme object

  // const theme = getTheme(darkMode.value ? "dark" : "light");

  // theme = responsiveFontSizes(theme);

  // Old theme code above. New responsive code below

  let theme = getTheme("light");

  theme = responsiveFontSizes(theme);

  return (
    <MuiThemeProvider theme={theme}>
      {/* Set global MUI styles */}
      <CssBaseline />
      {props.children}
    </MuiThemeProvider>
  );
};
