import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { getSlug } from "./../util/slug.js";
import 'swiper/swiper-bundle.min.css'




import SwiperCore, {
  Navigation
} from 'swiper';


SwiperCore.use([ Navigation ]);





export default function ImageCarousel(props) {



  var { item } = props;

  const userAgent = navigator.userAgent;

  var images = item.images;
  if (userAgent.toLowerCase().includes("googlebot")) {
    images = [item.images[0]];
  }


  return (
    <>

<Swiper modules={[Navigation]} slidesPerView={1} navigation={true}  spaceBetween={30}
breakpoints={{
  "640": {
    "slidesPerView": 2,
    "spaceBetween": 10
  },
  "768": {
    "slidesPerView": 3,
    "spaceBetween": 20
  },
  "1024": {
    "slidesPerView": 4,
    "spaceBetween": 20,
    
  }
}}

> 

        {images.map((image, index) => (
          <SwiperSlide key={getSlug(image)} >
            
         
                <img
              
                  src={image}
                  // priority
                  // layout="fill"
                  // objectFit="contain"
                  alt={item.title}
                  style={{display:'block', height:'250px', width:'100%', objectFit:'contain'}}
                />

       
          
          </SwiperSlide>
        ))}
      </Swiper>
     
    </>
  );
}
