import React, { useState, useEffect } from "react";
import { Button, useMediaQuery } from "@material-ui/core";
import LaunchIcon from '@material-ui/icons/Launch';
import ReactGA from "react-ga4";
import { ebayURL } from "../util/ebay";

function EbayItemButton(props) {

  const [itemStatus, setItemStatus] = useState("loading");

  var { item } = props;
  const [URL, setURL] = useState();

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("xs"));
  
  const buttonProps = {
    // variant: isSmallScreen ? "outlined" : "outlined",
    size: isSmallScreen ? "small" : "large"
  };


useEffect(() => {
console.log("EbayItemButton item", item);
}, [item]);

  useEffect(() => {

    const u = ebayURL(item);
    setURL(u);
    setItemStatus('success');

  }, [item]);

  useEffect(() =>{

if (props.onURL && itemStatus === 'success') {
  props.onURL = URL;
}

  }, [URL, itemStatus])

  function handleClick() {
    // Send a custom event
ReactGA.event({
category: "click",
action: "eBay Button click",
// label: "your label", // optional
// value: 99, // optional, must be a number
// nonInteraction: true, // optional, true/false
// transport: "xhr", // optional, beacon/xhr/image
});
console.log("eBayItemButton handleClick")


// analytics.track('amazonItemButton', {
//   item: item.title,
// })

  }

  if (itemStatus === "loading") {
    return null;
  }

  return (
    <>
      <a href={URL} onClick={()=>handleClick()}  target="_blank">
        <Button
        fullWidth
          // variant="outlined"
          disableElevation
          {...buttonProps}
          startIcon={<LaunchIcon  />}
          style={{
            // fontSize:'1em',
            lineHeight:'1em',
            backgroundColor: "#f44336",
            color: "white",
            fontWeight:'500',
            // textTransform: "none",
          }}
        >
          <div style={{display:'flex', height:'30px', alignItems:'center', textTransform:'none'}}>
          {/* {" "} */}
          {/* Details on eBay */}
          View Deal
         
          </div>
        </Button>
      </a>
    </>
  );
}

export default EbayItemButton;
