import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { getSlug } from "./../util/slug.js";
import { keywordsByGoogleSheet } from "./../util/gimmu";
import Gimmu from "../util/gimmu.js";

const { REACT_APP_GOOGLE_SHEET } = process.env;
const { REACT_APP_KEYWORD } = process.env;
const { REACT_APP_SLUG } = process.env;


const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },

  name: {
    overflow: "hidden",
    display: "-webkit-box",
    minHeight: "50px",
    WebkitLineClamp: 10,
    WebkitBoxOrient: "vertical",
  },
}));

function Keywords(props) {
  
  const siteSlug = process.env.REACT_APP_SITE_SLUG;

  const keywords = props.keywords ? props.keywords : REACT_APP_KEYWORD;
  const {items} = props;
  const {
    data: tagsData,
    error: tagsError,
    status: tagsStatus,
//  } = Gimmu.useTagsByKeywords("lionel-rail", keywords);
   } = Gimmu.useTagsByKeywords(siteSlug, keywords, items);

  useEffect(() => {
    console.log("tags", tagsData, tagsError, tagsStatus);
  }, [tagsData, tagsError, tagsStatus]);

//  const [outputKeywords, setOutputKeywords] = useState();

  function refreshPage() {
    window.location.reload(false);
  }

  const keywordsStatus = "success";
  //const keywords = "phone";
  const keywordsError = null;
  if (keywordsStatus === "loading") {
    return (
      <>
        <Skeleton
          variant="rect"
          width="100%"
          height={250}
          style={{ marginBottom: "3em" }}
        />
      </>
    );
  }

  const KeywordsButton = (props) => {
    return (
      <>
        <Button
          style={{ borderRadius: "15px", margin: 5 }}
          size="small"
          variant="outlined"
          component={Link}
          to={`/${getSlug(props.keywords)}`}
        >
          {props.keywords}
        </Button>
      </>
    );
  };

  if (tagsStatus !== "success") {
    return null;
  }

  if (tagsError !== null) {
    return <>COULD NOT GET ITEM DETAILS</>;
  }

  if (tagsData === undefined || tagsData === null) {
    return null;
  }

  if (tagsData === undefined) {
    return null;
  }
  return (
    <>
      {tagsData.map((k, i) => {
        return <KeywordsButton key={i} keywords={k} />;
      })}
    </>
  );

}

export default Keywords;
