import React, { Suspense, lazy } from "react";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import TermsPage from "./TermsPage";
import { Switch, Route, Router } from "./../util/router.js";
import NotFoundPage from "./not-found.js";
import Footer from "./../components/Footer";
import { ThemeProvider } from "./../util/theme.js";
import CollectionPage from "./CollectionPage";
import PrivacyPage from "./PrivacyPage";
import ReactGA from "react-ga4";

  // const TRACKING_ID = "UA-XXXXX-X"; // OUR_TRACKING_ID
  ReactGA.initialize("G-LYZ8DR82KG");


// const TermsPage = React.lazy(() => import('./TermsPage'))
// const PrivacyPage = React.lazy(() => import('./PrivacyPage'))

// const TermsPage = lazy(() => import("./TermsPage"));
// const PrivacyPage = lazy(() => import("./PrivacyPage"));
// const Footer = lazy(() => import("./../components/Footer"));

function App(props) {
  return (
    <ThemeProvider>
      {/* <Suspense fallback={<span></span>}> */}
        <Router>
          <Navbar color="default" />

          <Switch>
            <Route exact path="/" component={IndexPage} />
            <Route exact path="/notfound" component={NotFoundPage} />
            <Route exact path="/404" component={NotFoundPage} />
            <Route exact path="/termsandconditions" component={TermsPage} />
            <Route exact path="/privacy" component={PrivacyPage} />
            <Route exact path="/:eventid?tid=:trackId" component={CollectionPage} />
            <Route exact path="/:eventid" component={CollectionPage} />
            <Route component={NotFoundPage} />
          </Switch>

          <Footer
            bgColor="dark"
            size="small"
            bgImage=""
            bgImageOpacity={1}
            description="We make product searches easy."
            copyright="© 2022"
            // logo="Gimmu"
            // logoInverted="Gimmu"
            sticky={true}
          />
        </Router>
      {/* </Suspense> */}
    </ThemeProvider>
  );
}

export default App;
